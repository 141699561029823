/*-------------------------------------------------
	Style file contain theme components style
-------------------------------------------------*/
@import url(https://fonts.googleapis.com/css?family=Muli:300,300i,400,400i,500,600);
.materialize-red
{
    background-color: #e51c23 !important;
}

.materialize-red-text
{
    color: #e51c23 !important;
}

.materialize-red.lighten-5
{
    background-color: #fdeaeb !important;
}

.materialize-red-text.text-lighten-5
{
    color: #fdeaeb !important;
}

.materialize-red.lighten-4
{
    background-color: #f8c1c3 !important;
}

.materialize-red-text.text-lighten-4
{
    color: #f8c1c3 !important;
}

.materialize-red.lighten-3
{
    background-color: #f3989b !important;
}

.materialize-red-text.text-lighten-3
{
    color: #f3989b !important;
}

.materialize-red.lighten-2
{
    background-color: #ee6e73 !important;
}

.materialize-red-text.text-lighten-2
{
    color: #ee6e73 !important;
}

.materialize-red.lighten-1
{
    background-color: #ea454b !important;
}

.materialize-red-text.text-lighten-1
{
    color: #ea454b !important;
}

.materialize-red.darken-1
{
    background-color: #d0181e !important;
}

.materialize-red-text.text-darken-1
{
    color: #d0181e !important;
}

.materialize-red.darken-2
{
    background-color: #b9151b !important;
}

.materialize-red-text.text-darken-2
{
    color: #b9151b !important;
}

.materialize-red.darken-3
{
    background-color: #a21318 !important;
}

.materialize-red-text.text-darken-3
{
    color: #a21318 !important;
}

.materialize-red.darken-4
{
    background-color: #8b1014 !important;
}

.materialize-red-text.text-darken-4
{
    color: #8b1014 !important;
}

.red
{
    background-color: #f44336 !important;
}

.red-text
{
    color: #f44336 !important;
}

.red.lighten-5
{
    background-color: #ffebee !important;
}

.red-text.text-lighten-5
{
    color: #ffebee !important;
}

.red.lighten-4
{
    background-color: #ffcdd2 !important;
}

.red-text.text-lighten-4
{
    color: #ffcdd2 !important;
}

.red.lighten-3
{
    background-color: #ef9a9a !important;
}

.red-text.text-lighten-3
{
    color: #ef9a9a !important;
}

.red.lighten-2
{
    background-color: #e57373 !important;
}

.red-text.text-lighten-2
{
    color: #e57373 !important;
}

.red.lighten-1
{
    background-color: #ef5350 !important;
}

.red-text.text-lighten-1
{
    color: #ef5350 !important;
}

.red.darken-1
{
    background-color: #e53935 !important;
}

.red-text.text-darken-1
{
    color: #e53935 !important;
}

.red.darken-2
{
    background-color: #d32f2f !important;
}

.red-text.text-darken-2
{
    color: #d32f2f !important;
}

.red.darken-3
{
    background-color: #c62828 !important;
}

.red-text.text-darken-3
{
    color: #c62828 !important;
}

.red.darken-4
{
    background-color: #b71c1c !important;
}

.red-text.text-darken-4
{
    color: #b71c1c !important;
}

.red.accent-1
{
    background-color: #ff8a80 !important;
}

.red-text.text-accent-1
{
    color: #ff8a80 !important;
}

.red.accent-2
{
    background-color: #ff5252 !important;
}

.red-text.text-accent-2
{
    color: #ff5252 !important;
}

.red.accent-3
{
    background-color: #ff1744 !important;
}

.red-text.text-accent-3
{
    color: #ff1744 !important;
}

.red.accent-4
{
    background-color: #d50000 !important;
}

.red-text.text-accent-4
{
    color: #d50000 !important;
}

.pink
{
    background-color: #e91e63 !important;
}

.pink-text
{
    color: #e91e63 !important;
}

.pink.lighten-5
{
    background-color: #fce4ec !important;
}

.pink-text.text-lighten-5
{
    color: #fce4ec !important;
}

.pink.lighten-4
{
    background-color: #f8bbd0 !important;
}

.pink-text.text-lighten-4
{
    color: #f8bbd0 !important;
}

.pink.lighten-3
{
    background-color: #f48fb1 !important;
}

.pink-text.text-lighten-3
{
    color: #f48fb1 !important;
}

.pink.lighten-2
{
    background-color: #f06292 !important;
}

.pink-text.text-lighten-2
{
    color: #f06292 !important;
}

.pink.lighten-1
{
    background-color: #ec407a !important;
}

.pink-text.text-lighten-1
{
    color: #ec407a !important;
}

.pink.darken-1
{
    background-color: #d81b60 !important;
}

.pink-text.text-darken-1
{
    color: #d81b60 !important;
}

.pink.darken-2
{
    background-color: #c2185b !important;
}

.pink-text.text-darken-2
{
    color: #c2185b !important;
}

.pink.darken-3
{
    background-color: #ad1457 !important;
}

.pink-text.text-darken-3
{
    color: #ad1457 !important;
}

.pink.darken-4
{
    background-color: #880e4f !important;
}

.pink-text.text-darken-4
{
    color: #880e4f !important;
}

.pink.accent-1
{
    background-color: #ff80ab !important;
}

.pink-text.text-accent-1
{
    color: #ff80ab !important;
}

.pink.accent-2
{
    background-color: #ff4081 !important;
}

.pink-text.text-accent-2
{
    color: #ff4081 !important;
}

.pink.accent-3
{
    background-color: #f50057 !important;
}

.pink-text.text-accent-3
{
    color: #f50057 !important;
}

.pink.accent-4
{
    background-color: #c51162 !important;
}

.pink-text.text-accent-4
{
    color: #c51162 !important;
}

.purple
{
    background-color: #9c27b0 !important;
}

.purple-text
{
    color: #9c27b0 !important;
}

.purple.lighten-5
{
    background-color: #f3e5f5 !important;
}

.purple-text.text-lighten-5
{
    color: #f3e5f5 !important;
}

.purple.lighten-4
{
    background-color: #e1bee7 !important;
}

.purple-text.text-lighten-4
{
    color: #e1bee7 !important;
}

.purple.lighten-3
{
    background-color: #ce93d8 !important;
}

.purple-text.text-lighten-3
{
    color: #ce93d8 !important;
}

.purple.lighten-2
{
    background-color: #ba68c8 !important;
}

.purple-text.text-lighten-2
{
    color: #ba68c8 !important;
}

.purple.lighten-1
{
    background-color: #ab47bc !important;
}

.purple-text.text-lighten-1
{
    color: #ab47bc !important;
}

.purple.darken-1
{
    background-color: #8e24aa !important;
}

.purple-text.text-darken-1
{
    color: #8e24aa !important;
}

.purple.darken-2
{
    background-color: #7b1fa2 !important;
}

.purple-text.text-darken-2
{
    color: #7b1fa2 !important;
}

.purple.darken-3
{
    background-color: #6a1b9a !important;
}

.purple-text.text-darken-3
{
    color: #6a1b9a !important;
}

.purple.darken-4
{
    background-color: #4a148c !important;
}

.purple-text.text-darken-4
{
    color: #4a148c !important;
}

.purple.accent-1
{
    background-color: #ea80fc !important;
}

.purple-text.text-accent-1
{
    color: #ea80fc !important;
}

.purple.accent-2
{
    background-color: #e040fb !important;
}

.purple-text.text-accent-2
{
    color: #e040fb !important;
}

.purple.accent-3
{
    background-color: #d500f9 !important;
}

.purple-text.text-accent-3
{
    color: #d500f9 !important;
}

.purple.accent-4
{
    background-color: #a0f !important;
}

.purple-text.text-accent-4
{
    color: #a0f !important;
}

.deep-purple
{
    background-color: #673ab7 !important;
}

.deep-purple-text
{
    color: #673ab7 !important;
}

.deep-purple.lighten-5
{
    background-color: #ede7f6 !important;
}

.deep-purple-text.text-lighten-5
{
    color: #ede7f6 !important;
}

.deep-purple.lighten-4
{
    background-color: #d1c4e9 !important;
}

.deep-purple-text.text-lighten-4
{
    color: #d1c4e9 !important;
}

.deep-purple.lighten-3
{
    background-color: #b39ddb !important;
}

.deep-purple-text.text-lighten-3
{
    color: #b39ddb !important;
}

.deep-purple.lighten-2
{
    background-color: #9575cd !important;
}

.deep-purple-text.text-lighten-2
{
    color: #9575cd !important;
}

.deep-purple.lighten-1
{
    background-color: #7e57c2 !important;
}

.deep-purple-text.text-lighten-1
{
    color: #7e57c2 !important;
}

.deep-purple.darken-1
{
    background-color: #5e35b1 !important;
}

.deep-purple-text.text-darken-1
{
    color: #5e35b1 !important;
}

.deep-purple.darken-2
{
    background-color: #512da8 !important;
}

.deep-purple-text.text-darken-2
{
    color: #512da8 !important;
}

.deep-purple.darken-3
{
    background-color: #4527a0 !important;
}

.deep-purple-text.text-darken-3
{
    color: #4527a0 !important;
}

.deep-purple.darken-4
{
    background-color: #311b92 !important;
}

.deep-purple-text.text-darken-4
{
    color: #311b92 !important;
}

.deep-purple.accent-1
{
    background-color: #b388ff !important;
}

.deep-purple-text.text-accent-1
{
    color: #b388ff !important;
}

.deep-purple.accent-2
{
    background-color: #7c4dff !important;
}

.deep-purple-text.text-accent-2
{
    color: #7c4dff !important;
}

.deep-purple.accent-3
{
    background-color: #651fff !important;
}

.deep-purple-text.text-accent-3
{
    color: #651fff !important;
}

.deep-purple.accent-4
{
    background-color: #6200ea !important;
}

.deep-purple-text.text-accent-4
{
    color: #6200ea !important;
}

.indigo
{
    background-color: #3f51b5 !important;
}

.indigo-text
{
    color: #3f51b5 !important;
}

.indigo.lighten-5
{
    background-color: #e8eaf6 !important;
}

.indigo-text.text-lighten-5
{
    color: #e8eaf6 !important;
}

.indigo.lighten-4
{
    background-color: #c5cae9 !important;
}

.indigo-text.text-lighten-4
{
    color: #c5cae9 !important;
}

.indigo.lighten-3
{
    background-color: #9fa8da !important;
}

.indigo-text.text-lighten-3
{
    color: #9fa8da !important;
}

.indigo.lighten-2
{
    background-color: #7986cb !important;
}

.indigo-text.text-lighten-2
{
    color: #7986cb !important;
}

.indigo.lighten-1
{
    background-color: #5c6bc0 !important;
}

.indigo-text.text-lighten-1
{
    color: #5c6bc0 !important;
}

.indigo.darken-1
{
    background-color: #3949ab !important;
}

.indigo-text.text-darken-1
{
    color: #3949ab !important;
}

.indigo.darken-2
{
    background-color: #303f9f !important;
}

.indigo-text.text-darken-2
{
    color: #303f9f !important;
}

.indigo.darken-3
{
    background-color: #283593 !important;
}

.indigo-text.text-darken-3
{
    color: #283593 !important;
}

.indigo.darken-4
{
    background-color: #1a237e !important;
}

.indigo-text.text-darken-4
{
    color: #1a237e !important;
}

.indigo.accent-1
{
    background-color: #8c9eff !important;
}

.indigo-text.text-accent-1
{
    color: #8c9eff !important;
}

.indigo.accent-2
{
    background-color: #536dfe !important;
}

.indigo-text.text-accent-2
{
    color: #536dfe !important;
}

.indigo.accent-3
{
    background-color: #3d5afe !important;
}

.indigo-text.text-accent-3
{
    color: #3d5afe !important;
}

.indigo.accent-4
{
    background-color: #304ffe !important;
}

.indigo-text.text-accent-4
{
    color: #304ffe !important;
}

.blue
{
    background-color: #2196f3 !important;
}

.blue-text
{
    color: #2196f3 !important;
}

.blue.lighten-5
{
    background-color: #e3f2fd !important;
}

.blue-text.text-lighten-5
{
    color: #e3f2fd !important;
}

.blue.lighten-4
{
    background-color: #bbdefb !important;
}

.blue-text.text-lighten-4
{
    color: #bbdefb !important;
}

.blue.lighten-3
{
    background-color: #90caf9 !important;
}

.blue-text.text-lighten-3
{
    color: #90caf9 !important;
}

.blue.lighten-2
{
    background-color: #64b5f6 !important;
}

.blue-text.text-lighten-2
{
    color: #64b5f6 !important;
}

.blue.lighten-1
{
    background-color: #42a5f5 !important;
}

.blue-text.text-lighten-1
{
    color: #42a5f5 !important;
}

.blue.darken-1
{
    background-color: #1e88e5 !important;
}

.blue-text.text-darken-1
{
    color: #1e88e5 !important;
}

.blue.darken-2
{
    background-color: #1976d2 !important;
}

.blue-text.text-darken-2
{
    color: #1976d2 !important;
}

.blue.darken-3
{
    background-color: #1565c0 !important;
}

.blue-text.text-darken-3
{
    color: #1565c0 !important;
}

.blue.darken-4
{
    background-color: #0d47a1 !important;
}

.blue-text.text-darken-4
{
    color: #0d47a1 !important;
}

.blue.accent-1
{
    background-color: #82b1ff !important;
}

.blue-text.text-accent-1
{
    color: #82b1ff !important;
}

.blue.accent-2
{
    background-color: #448aff !important;
}

.blue-text.text-accent-2
{
    color: #448aff !important;
}

.blue.accent-3
{
    background-color: #2979ff !important;
}

.blue-text.text-accent-3
{
    color: #2979ff !important;
}

.blue.accent-4
{
    background-color: #2962ff !important;
}

.blue-text.text-accent-4
{
    color: #2962ff !important;
}

.light-blue
{
    background-color: #03a9f4 !important;
}

.light-blue-text
{
    color: #03a9f4 !important;
}

.light-blue.lighten-5
{
    background-color: #e1f5fe !important;
}

.light-blue-text.text-lighten-5
{
    color: #e1f5fe !important;
}

.light-blue.lighten-4
{
    background-color: #b3e5fc !important;
}

.light-blue-text.text-lighten-4
{
    color: #b3e5fc !important;
}

.light-blue.lighten-3
{
    background-color: #81d4fa !important;
}

.light-blue-text.text-lighten-3
{
    color: #81d4fa !important;
}

.light-blue.lighten-2
{
    background-color: #4fc3f7 !important;
}

.light-blue-text.text-lighten-2
{
    color: #4fc3f7 !important;
}

.light-blue.lighten-1
{
    background-color: #29b6f6 !important;
}

.light-blue-text.text-lighten-1
{
    color: #29b6f6 !important;
}

.light-blue.darken-1
{
    background-color: #039be5 !important;
}

.light-blue-text.text-darken-1
{
    color: #039be5 !important;
}

.light-blue.darken-2
{
    background-color: #0288d1 !important;
}

.light-blue-text.text-darken-2
{
    color: #0288d1 !important;
}

.light-blue.darken-3
{
    background-color: #0277bd !important;
}

.light-blue-text.text-darken-3
{
    color: #0277bd !important;
}

.light-blue.darken-4
{
    background-color: #01579b !important;
}

.light-blue-text.text-darken-4
{
    color: #01579b !important;
}

.light-blue.accent-1
{
    background-color: #80d8ff !important;
}

.light-blue-text.text-accent-1
{
    color: #80d8ff !important;
}

.light-blue.accent-2
{
    background-color: #40c4ff !important;
}

.light-blue-text.text-accent-2
{
    color: #40c4ff !important;
}

.light-blue.accent-3
{
    background-color: #00b0ff !important;
}

.light-blue-text.text-accent-3
{
    color: #00b0ff !important;
}

.light-blue.accent-4
{
    background-color: #0091ea !important;
}

.light-blue-text.text-accent-4
{
    color: #0091ea !important;
}

.cyan
{
    background-color: #00bcd4 !important;
}

.cyan-text
{
    color: #00bcd4 !important;
}

.cyan.lighten-5
{
    background-color: #e0f7fa !important;
}

.cyan-text.text-lighten-5
{
    color: #e0f7fa !important;
}

.cyan.lighten-4
{
    background-color: #b2ebf2 !important;
}

.cyan-text.text-lighten-4
{
    color: #b2ebf2 !important;
}

.cyan.lighten-3
{
    background-color: #80deea !important;
}

.cyan-text.text-lighten-3
{
    color: #80deea !important;
}

.cyan.lighten-2
{
    background-color: #4dd0e1 !important;
}

.cyan-text.text-lighten-2
{
    color: #4dd0e1 !important;
}

.cyan.lighten-1
{
    background-color: #26c6da !important;
}

.cyan-text.text-lighten-1
{
    color: #26c6da !important;
}

.cyan.darken-1
{
    background-color: #00acc1 !important;
}

.cyan-text.text-darken-1
{
    color: #00acc1 !important;
}

.cyan.darken-2
{
    background-color: #0097a7 !important;
}

.cyan-text.text-darken-2
{
    color: #0097a7 !important;
}

.cyan.darken-3
{
    background-color: #00838f !important;
}

.cyan-text.text-darken-3
{
    color: #00838f !important;
}

.cyan.darken-4
{
    background-color: #006064 !important;
}

.cyan-text.text-darken-4
{
    color: #006064 !important;
}

.cyan.accent-1
{
    background-color: #84ffff !important;
}

.cyan-text.text-accent-1
{
    color: #84ffff !important;
}

.cyan.accent-2
{
    background-color: #18ffff !important;
}

.cyan-text.text-accent-2
{
    color: #18ffff !important;
}

.cyan.accent-3
{
    background-color: #00e5ff !important;
}

.cyan-text.text-accent-3
{
    color: #00e5ff !important;
}

.cyan.accent-4
{
    background-color: #00b8d4 !important;
}

.cyan-text.text-accent-4
{
    color: #00b8d4 !important;
}

.teal
{
    background-color: #009688 !important;
}

.teal-text
{
    color: #009688 !important;
}

.teal.lighten-5
{
    background-color: #e0f2f1 !important;
}

.teal-text.text-lighten-5
{
    color: #e0f2f1 !important;
}

.teal.lighten-4
{
    background-color: #b2dfdb !important;
}

.teal-text.text-lighten-4
{
    color: #b2dfdb !important;
}

.teal.lighten-3
{
    background-color: #80cbc4 !important;
}

.teal-text.text-lighten-3
{
    color: #80cbc4 !important;
}

.teal.lighten-2
{
    background-color: #4db6ac !important;
}

.teal-text.text-lighten-2
{
    color: #4db6ac !important;
}

.teal.lighten-1
{
    background-color: #26a69a !important;
}

.teal-text.text-lighten-1
{
    color: #26a69a !important;
}

.teal.darken-1
{
    background-color: #00897b !important;
}

.teal-text.text-darken-1
{
    color: #00897b !important;
}

.teal.darken-2
{
    background-color: #00796b !important;
}

.teal-text.text-darken-2
{
    color: #00796b !important;
}

.teal.darken-3
{
    background-color: #00695c !important;
}

.teal-text.text-darken-3
{
    color: #00695c !important;
}

.teal.darken-4
{
    background-color: #004d40 !important;
}

.teal-text.text-darken-4
{
    color: #004d40 !important;
}

.teal.accent-1
{
    background-color: #a7ffeb !important;
}

.teal-text.text-accent-1
{
    color: #a7ffeb !important;
}

.teal.accent-2
{
    background-color: #64ffda !important;
}

.teal-text.text-accent-2
{
    color: #64ffda !important;
}

.teal.accent-3
{
    background-color: #1de9b6 !important;
}

.teal-text.text-accent-3
{
    color: #1de9b6 !important;
}

.teal.accent-4
{
    background-color: #00bfa5 !important;
}

.teal-text.text-accent-4
{
    color: #00bfa5 !important;
}

.green
{
    background-color: #4caf50 !important;
}

.green-text
{
    color: #4caf50 !important;
}

.green.lighten-5
{
    background-color: #e8f5e9 !important;
}

.green-text.text-lighten-5
{
    color: #e8f5e9 !important;
}

.green.lighten-4
{
    background-color: #c8e6c9 !important;
}

.green-text.text-lighten-4
{
    color: #c8e6c9 !important;
}

.green.lighten-3
{
    background-color: #a5d6a7 !important;
}

.green-text.text-lighten-3
{
    color: #a5d6a7 !important;
}

.green.lighten-2
{
    background-color: #81c784 !important;
}

.green-text.text-lighten-2
{
    color: #81c784 !important;
}

.green.lighten-1
{
    background-color: #66bb6a !important;
}

.green-text.text-lighten-1
{
    color: #66bb6a !important;
}

.green.darken-1
{
    background-color: #43a047 !important;
}

.green-text.text-darken-1
{
    color: #43a047 !important;
}

.green.darken-2
{
    background-color: #388e3c !important;
}

.green-text.text-darken-2
{
    color: #388e3c !important;
}

.green.darken-3
{
    background-color: #2e7d32 !important;
}

.green-text.text-darken-3
{
    color: #2e7d32 !important;
}

.green.darken-4
{
    background-color: #1b5e20 !important;
}

.green-text.text-darken-4
{
    color: #1b5e20 !important;
}

.green.accent-1
{
    background-color: #b9f6ca !important;
}

.green-text.text-accent-1
{
    color: #b9f6ca !important;
}

.green.accent-2
{
    background-color: #69f0ae !important;
}

.green-text.text-accent-2
{
    color: #69f0ae !important;
}

.green.accent-3
{
    background-color: #00e676 !important;
}

.green-text.text-accent-3
{
    color: #00e676 !important;
}

.green.accent-4
{
    background-color: #00c853 !important;
}

.green-text.text-accent-4
{
    color: #00c853 !important;
}

.light-green
{
    background-color: #8bc34a !important;
}

.light-green-text
{
    color: #8bc34a !important;
}

.light-green.lighten-5
{
    background-color: #f1f8e9 !important;
}

.light-green-text.text-lighten-5
{
    color: #f1f8e9 !important;
}

.light-green.lighten-4
{
    background-color: #dcedc8 !important;
}

.light-green-text.text-lighten-4
{
    color: #dcedc8 !important;
}

.light-green.lighten-3
{
    background-color: #c5e1a5 !important;
}

.light-green-text.text-lighten-3
{
    color: #c5e1a5 !important;
}

.light-green.lighten-2
{
    background-color: #aed581 !important;
}

.light-green-text.text-lighten-2
{
    color: #aed581 !important;
}

.light-green.lighten-1
{
    background-color: #9ccc65 !important;
}

.light-green-text.text-lighten-1
{
    color: #9ccc65 !important;
}

.light-green.darken-1
{
    background-color: #7cb342 !important;
}

.light-green-text.text-darken-1
{
    color: #7cb342 !important;
}

.light-green.darken-2
{
    background-color: #689f38 !important;
}

.light-green-text.text-darken-2
{
    color: #689f38 !important;
}

.light-green.darken-3
{
    background-color: #558b2f !important;
}

.light-green-text.text-darken-3
{
    color: #558b2f !important;
}

.light-green.darken-4
{
    background-color: #33691e !important;
}

.light-green-text.text-darken-4
{
    color: #33691e !important;
}

.light-green.accent-1
{
    background-color: #ccff90 !important;
}

.light-green-text.text-accent-1
{
    color: #ccff90 !important;
}

.light-green.accent-2
{
    background-color: #b2ff59 !important;
}

.light-green-text.text-accent-2
{
    color: #b2ff59 !important;
}

.light-green.accent-3
{
    background-color: #76ff03 !important;
}

.light-green-text.text-accent-3
{
    color: #76ff03 !important;
}

.light-green.accent-4
{
    background-color: #64dd17 !important;
}

.light-green-text.text-accent-4
{
    color: #64dd17 !important;
}

.lime
{
    background-color: #cddc39 !important;
}

.lime-text
{
    color: #cddc39 !important;
}

.lime.lighten-5
{
    background-color: #f9fbe7 !important;
}

.lime-text.text-lighten-5
{
    color: #f9fbe7 !important;
}

.lime.lighten-4
{
    background-color: #f0f4c3 !important;
}

.lime-text.text-lighten-4
{
    color: #f0f4c3 !important;
}

.lime.lighten-3
{
    background-color: #e6ee9c !important;
}

.lime-text.text-lighten-3
{
    color: #e6ee9c !important;
}

.lime.lighten-2
{
    background-color: #dce775 !important;
}

.lime-text.text-lighten-2
{
    color: #dce775 !important;
}

.lime.lighten-1
{
    background-color: #d4e157 !important;
}

.lime-text.text-lighten-1
{
    color: #d4e157 !important;
}

.lime.darken-1
{
    background-color: #c0ca33 !important;
}

.lime-text.text-darken-1
{
    color: #c0ca33 !important;
}

.lime.darken-2
{
    background-color: #afb42b !important;
}

.lime-text.text-darken-2
{
    color: #afb42b !important;
}

.lime.darken-3
{
    background-color: #9e9d24 !important;
}

.lime-text.text-darken-3
{
    color: #9e9d24 !important;
}

.lime.darken-4
{
    background-color: #827717 !important;
}

.lime-text.text-darken-4
{
    color: #827717 !important;
}

.lime.accent-1
{
    background-color: #f4ff81 !important;
}

.lime-text.text-accent-1
{
    color: #f4ff81 !important;
}

.lime.accent-2
{
    background-color: #eeff41 !important;
}

.lime-text.text-accent-2
{
    color: #eeff41 !important;
}

.lime.accent-3
{
    background-color: #c6ff00 !important;
}

.lime-text.text-accent-3
{
    color: #c6ff00 !important;
}

.lime.accent-4
{
    background-color: #aeea00 !important;
}

.lime-text.text-accent-4
{
    color: #aeea00 !important;
}

.yellow
{
    background-color: #ffeb3b !important;
}

.yellow-text
{
    color: #ffeb3b !important;
}

.yellow.lighten-5
{
    background-color: #fffde7 !important;
}

.yellow-text.text-lighten-5
{
    color: #fffde7 !important;
}

.yellow.lighten-4
{
    background-color: #fff9c4 !important;
}

.yellow-text.text-lighten-4
{
    color: #fff9c4 !important;
}

.yellow.lighten-3
{
    background-color: #fff59d !important;
}

.yellow-text.text-lighten-3
{
    color: #fff59d !important;
}

.yellow.lighten-2
{
    background-color: #fff176 !important;
}

.yellow-text.text-lighten-2
{
    color: #fff176 !important;
}

.yellow.lighten-1
{
    background-color: #ffee58 !important;
}

.yellow-text.text-lighten-1
{
    color: #ffee58 !important;
}

.yellow.darken-1
{
    background-color: #fdd835 !important;
}

.yellow-text.text-darken-1
{
    color: #fdd835 !important;
}

.yellow.darken-2
{
    background-color: #fbc02d !important;
}

.yellow-text.text-darken-2
{
    color: #fbc02d !important;
}

.yellow.darken-3
{
    background-color: #f9a825 !important;
}

.yellow-text.text-darken-3
{
    color: #f9a825 !important;
}

.yellow.darken-4
{
    background-color: #f57f17 !important;
}

.yellow-text.text-darken-4
{
    color: #f57f17 !important;
}

.yellow.accent-1
{
    background-color: #ffff8d !important;
}

.yellow-text.text-accent-1
{
    color: #ffff8d !important;
}

.yellow.accent-2
{
    background-color: #ff0 !important;
}

.yellow-text.text-accent-2
{
    color: #ff0 !important;
}

.yellow.accent-3
{
    background-color: #ffea00 !important;
}

.yellow-text.text-accent-3
{
    color: #ffea00 !important;
}

.yellow.accent-4
{
    background-color: #ffd600 !important;
}

.yellow-text.text-accent-4
{
    color: #ffd600 !important;
}

.amber
{
    background-color: #ffc107 !important;
}

.amber-text
{
    color: #ffc107 !important;
}

.amber.lighten-5
{
    background-color: #fff8e1 !important;
}

.amber-text.text-lighten-5
{
    color: #fff8e1 !important;
}

.amber.lighten-4
{
    background-color: #ffecb3 !important;
}

.amber-text.text-lighten-4
{
    color: #ffecb3 !important;
}

.amber.lighten-3
{
    background-color: #ffe082 !important;
}

.amber-text.text-lighten-3
{
    color: #ffe082 !important;
}

.amber.lighten-2
{
    background-color: #ffd54f !important;
}

.amber-text.text-lighten-2
{
    color: #ffd54f !important;
}

.amber.lighten-1
{
    background-color: #ffca28 !important;
}

.amber-text.text-lighten-1
{
    color: #ffca28 !important;
}

.amber.darken-1
{
    background-color: #ffb300 !important;
}

.amber-text.text-darken-1
{
    color: #ffb300 !important;
}

.amber.darken-2
{
    background-color: #ffa000 !important;
}

.amber-text.text-darken-2
{
    color: #ffa000 !important;
}

.amber.darken-3
{
    background-color: #ff8f00 !important;
}

.amber-text.text-darken-3
{
    color: #ff8f00 !important;
}

.amber.darken-4
{
    background-color: #ff6f00 !important;
}

.amber-text.text-darken-4
{
    color: #ff6f00 !important;
}

.amber.accent-1
{
    background-color: #ffe57f !important;
}

.amber-text.text-accent-1
{
    color: #ffe57f !important;
}

.amber.accent-2
{
    background-color: #ffd740 !important;
}

.amber-text.text-accent-2
{
    color: #ffd740 !important;
}

.amber.accent-3
{
    background-color: #ffc400 !important;
}

.amber-text.text-accent-3
{
    color: #ffc400 !important;
}

.amber.accent-4
{
    background-color: #ffab00 !important;
}

.amber-text.text-accent-4
{
    color: #ffab00 !important;
}

.orange
{
    background-color: #ff9800 !important;
}

.orange-text
{
    color: #ff9800 !important;
}

.orange.lighten-5
{
    background-color: #fff3e0 !important;
}

.orange-text.text-lighten-5
{
    color: #fff3e0 !important;
}

.orange.lighten-4
{
    background-color: #ffe0b2 !important;
}

.orange-text.text-lighten-4
{
    color: #ffe0b2 !important;
}

.orange.lighten-3
{
    background-color: #ffcc80 !important;
}

.orange-text.text-lighten-3
{
    color: #ffcc80 !important;
}

.orange.lighten-2
{
    background-color: #ffb74d !important;
}

.orange-text.text-lighten-2
{
    color: #ffb74d !important;
}

.orange.lighten-1
{
    background-color: #ffa726 !important;
}

.orange-text.text-lighten-1
{
    color: #ffa726 !important;
}

.orange.darken-1
{
    background-color: #fb8c00 !important;
}

.orange-text.text-darken-1
{
    color: #fb8c00 !important;
}

.orange.darken-2
{
    background-color: #f57c00 !important;
}

.orange-text.text-darken-2
{
    color: #f57c00 !important;
}

.orange.darken-3
{
    background-color: #ef6c00 !important;
}

.orange-text.text-darken-3
{
    color: #ef6c00 !important;
}

.orange.darken-4
{
    background-color: #e65100 !important;
}

.orange-text.text-darken-4
{
    color: #e65100 !important;
}

.orange.accent-1
{
    background-color: #ffd180 !important;
}

.orange-text.text-accent-1
{
    color: #ffd180 !important;
}

.orange.accent-2
{
    background-color: #ffab40 !important;
}

.orange-text.text-accent-2
{
    color: #ffab40 !important;
}

.orange.accent-3
{
    background-color: #ff9100 !important;
}

.orange-text.text-accent-3
{
    color: #ff9100 !important;
}

.orange.accent-4
{
    background-color: #ff6d00 !important;
}

.orange-text.text-accent-4
{
    color: #ff6d00 !important;
}

.deep-orange
{
    background-color: #ff5722 !important;
}

.deep-orange-text
{
    color: #ff5722 !important;
}

.deep-orange.lighten-5
{
    background-color: #fbe9e7 !important;
}

.deep-orange-text.text-lighten-5
{
    color: #fbe9e7 !important;
}

.deep-orange.lighten-4
{
    background-color: #ffccbc !important;
}

.deep-orange-text.text-lighten-4
{
    color: #ffccbc !important;
}

.deep-orange.lighten-3
{
    background-color: #ffab91 !important;
}

.deep-orange-text.text-lighten-3
{
    color: #ffab91 !important;
}

.deep-orange.lighten-2
{
    background-color: #ff8a65 !important;
}

.deep-orange-text.text-lighten-2
{
    color: #ff8a65 !important;
}

.deep-orange.lighten-1
{
    background-color: #ff7043 !important;
}

.deep-orange-text.text-lighten-1
{
    color: #ff7043 !important;
}

.deep-orange.darken-1
{
    background-color: #f4511e !important;
}

.deep-orange-text.text-darken-1
{
    color: #f4511e !important;
}

.deep-orange.darken-2
{
    background-color: #e64a19 !important;
}

.deep-orange-text.text-darken-2
{
    color: #e64a19 !important;
}

.deep-orange.darken-3
{
    background-color: #d84315 !important;
}

.deep-orange-text.text-darken-3
{
    color: #d84315 !important;
}

.deep-orange.darken-4
{
    background-color: #bf360c !important;
}

.deep-orange-text.text-darken-4
{
    color: #bf360c !important;
}

.deep-orange.accent-1
{
    background-color: #ff9e80 !important;
}

.deep-orange-text.text-accent-1
{
    color: #ff9e80 !important;
}

.deep-orange.accent-2
{
    background-color: #ff6e40 !important;
}

.deep-orange-text.text-accent-2
{
    color: #ff6e40 !important;
}

.deep-orange.accent-3
{
    background-color: #ff3d00 !important;
}

.deep-orange-text.text-accent-3
{
    color: #ff3d00 !important;
}

.deep-orange.accent-4
{
    background-color: #dd2c00 !important;
}

.deep-orange-text.text-accent-4
{
    color: #dd2c00 !important;
}

.brown
{
    background-color: #795548 !important;
}

.brown-text
{
    color: #795548 !important;
}

.brown.lighten-5
{
    background-color: #efebe9 !important;
}

.brown-text.text-lighten-5
{
    color: #efebe9 !important;
}

.brown.lighten-4
{
    background-color: #d7ccc8 !important;
}

.brown-text.text-lighten-4
{
    color: #d7ccc8 !important;
}

.brown.lighten-3
{
    background-color: #bcaaa4 !important;
}

.brown-text.text-lighten-3
{
    color: #bcaaa4 !important;
}

.brown.lighten-2
{
    background-color: #a1887f !important;
}

.brown-text.text-lighten-2
{
    color: #a1887f !important;
}

.brown.lighten-1
{
    background-color: #8d6e63 !important;
}

.brown-text.text-lighten-1
{
    color: #8d6e63 !important;
}

.brown.darken-1
{
    background-color: #6d4c41 !important;
}

.brown-text.text-darken-1
{
    color: #6d4c41 !important;
}

.brown.darken-2
{
    background-color: #5d4037 !important;
}

.brown-text.text-darken-2
{
    color: #5d4037 !important;
}

.brown.darken-3
{
    background-color: #4e342e !important;
}

.brown-text.text-darken-3
{
    color: #4e342e !important;
}

.brown.darken-4
{
    background-color: #3e2723 !important;
}

.brown-text.text-darken-4
{
    color: #3e2723 !important;
}

.blue-grey
{
    background-color: #607d8b !important;
}

.blue-grey-text
{
    color: #607d8b !important;
}

.blue-grey.lighten-5
{
    background-color: #eceff1 !important;
}

.blue-grey-text.text-lighten-5
{
    color: #eceff1 !important;
}

.blue-grey.lighten-4
{
    background-color: #cfd8dc !important;
}

.blue-grey-text.text-lighten-4
{
    color: #cfd8dc !important;
}

.blue-grey.lighten-3
{
    background-color: #b0bec5 !important;
}

.blue-grey-text.text-lighten-3
{
    color: #b0bec5 !important;
}

.blue-grey.lighten-2
{
    background-color: #90a4ae !important;
}

.blue-grey-text.text-lighten-2
{
    color: #90a4ae !important;
}

.blue-grey.lighten-1
{
    background-color: #78909c !important;
}

.blue-grey-text.text-lighten-1
{
    color: #78909c !important;
}

.blue-grey.darken-1
{
    background-color: #546e7a !important;
}

.blue-grey-text.text-darken-1
{
    color: #546e7a !important;
}

.blue-grey.darken-2
{
    background-color: #455a64 !important;
}

.blue-grey-text.text-darken-2
{
    color: #455a64 !important;
}

.blue-grey.darken-3
{
    background-color: #37474f !important;
}

.blue-grey-text.text-darken-3
{
    color: #37474f !important;
}

.blue-grey.darken-4
{
    background-color: #263238 !important;
}

.blue-grey-text.text-darken-4
{
    color: #263238 !important;
}

.grey
{
    background-color: #9e9e9e !important;
}

.grey-text
{
    color: #9e9e9e !important;
}

.grey.lighten-5
{
    background-color: #fafafa !important;
}

.grey-text.text-lighten-5
{
    color: #fafafa !important;
}

.grey.lighten-4
{
    background-color: #f5f5f5 !important;
}

.grey-text.text-lighten-4
{
    color: #f5f5f5 !important;
}

.grey.lighten-3
{
    background-color: #eee !important;
}

.grey-text.text-lighten-3
{
    color: #eee !important;
}

.grey.lighten-2
{
    background-color: #e0e0e0 !important;
}

.grey-text.text-lighten-2
{
    color: #e0e0e0 !important;
}

.grey.lighten-1
{
    background-color: #bdbdbd !important;
}

.grey-text.text-lighten-1
{
    color: #bdbdbd !important;
}

.grey.darken-1
{
    background-color: #757575 !important;
}

.grey-text.text-darken-1
{
    color: #757575 !important;
}

.grey.darken-2
{
    background-color: #616161 !important;
}

.grey-text.text-darken-2
{
    color: #616161 !important;
}

.grey.darken-3
{
    background-color: #424242 !important;
}

.grey-text.text-darken-3
{
    color: #424242 !important;
}

.grey.darken-4
{
    background-color: #212121 !important;
}

.grey-text.text-darken-4
{
    color: #212121 !important;
}

.black
{
    background-color: #000 !important;
}

.black-text
{
    color: #000 !important;
}

.white
{
    background-color: #fff !important;
}

.white-text
{
    color: #fff !important;
}

.transparent
{
    background-color: transparent !important;
}

.transparent-text
{
    color: transparent !important;
}

/*----------------------------------------
  General Layout Style
------------------------------------------*/
html,
body
{
    height: 100%;
}

body
{
    background-color: #f9f9f9;
}

@media only screen and (max-width: 600px)
{
    .layout-semi-dark #header nav.navbar-color
    {
        background: #37474f;
    }
}

.preload-transitions *
{
    -webkit-transition: none !important;
    transition: none !important;
}

#main
{
    min-height: calc(100% - 116px);
    padding-left: 0;

    -webkit-transition: .3s ease all;
    transition: .3s ease all;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

#main.main-full
{
    padding-left: 0;
}

#main .content-wrapper-before
{
    position: absolute;
    z-index: -1;
    top: 64px;
    left: 0;

    width: 100%;
    height: 300px;

    -webkit-transition: .3s ease all;
    transition: .3s ease all;
}

footer
{
    padding: 0 10px;
}

footer.page-footer
{
    padding-top: 1px;
}

select
{
    width: 100%;
    height: 3rem;
    padding: 5px;

    border: none;
    border-bottom: 1px solid #bdbdbd;
    border-radius: 2px;
    background-color: transparent;
}


@media only screen and (min-width: 993px)
{
    #main
    {
        padding-left: 250px;
    }
    #main.main-full
    {
        padding-left: 64px;
    }
    .nav-lock ~ footer
    {
        padding-left: 257px;
    }
    footer
    {
        padding-left: 306px;
    }
}

@media only screen and (max-width: 600px)
{
    #main .content-wrapper-before
    {
        top: 56px;
    }
}

.ps-scrollbar-x-rail
{
    z-index: 9999;
}

.ps-scrollbar-y-rail
{
    z-index: 9999;
}

.bg-opacity-8
{
    background-color: rgba(255, 255, 255, .8);
}
.bg-opacity-5
{
    background-color: rgba(255, 255, 255, .5);
}
.bg-opacity-3
{
    background-color: rgba(255, 255, 255, .3);
}



.height-100vh
{
    height: 100vh;
}

button,
html [type='button'],
[type='reset'],
[type='submit']
{
    cursor: pointer;

    -webkit-appearance: none;
}

/*----------------------------------------
  Healper Classes
------------------------------------------*/
/* Width */
.width-10
{
    width: 10% !important;
}

.width-20
{
    width: 20% !important;
}

.width-30
{
    width: 30% !important;
}

.width-40
{
    width: 40% !important;
}

.width-50
{
    width: 50% !important;
}

.width-60
{
    width: 60% !important;
}

.width-70
{
    width: 70% !important;
}

.width-80
{
    width: 80% !important;
}

.width-90
{
    width: 90% !important;
}

.width-100
{
    width: 100% !important;
}

/* Border Radius */
.border-radius-1
{
    border-radius: 1px !important;
}

.border-radius-2
{
    border-radius: 2px !important;
}

.border-radius-3
{
    border-radius: 3px !important;
}

.border-radius-4
{
    border-radius: 4px !important;
}

.border-radius-5
{
    border-radius: 5px !important;
}

.border-radius-6
{
    border-radius: 6px !important;
}

.border-radius-7
{
    border-radius: 7px !important;
}

.border-radius-8
{
    border-radius: 8px !important;
}

.border-radius-9
{
    border-radius: 9px !important;
}

.border-radius-10
{
    border-radius: 10px !important;
}

.border-round
{
    border-radius: 150px !important;
}

/* Font Weight */
.font-weight-100
{
    font-weight: 100 !important;
}

.font-weight-200
{
    font-weight: 200 !important;
}

.font-weight-300
{
    font-weight: 300 !important;
}

.font-weight-400
{
    font-weight: 400 !important;
}

.font-weight-500
{
    font-weight: 500 !important;
}

.font-weight-600
{
    font-weight: 600 !important;
}

.font-weight-700
{
    font-weight: 700 !important;
}

.font-weight-800
{
    font-weight: 800 !important;
}

.font-weight-900
{
    font-weight: 900 !important;
}

.m-0
{
    margin: 0 !important;
}

/* Margin Top */
.mt-0
{
    margin-top: 0 !important;
}

.mt-1
{
    margin-top: 1% !important;
}

.mt-2
{
    margin-top: 2% !important;
}

.mt-3
{
    margin-top: 3% !important;
}

.mt-4
{
    margin-top: 4% !important;
}

.mt-5
{
    margin-top: 5% !important;
}

.mt-6
{
    margin-top: 6% !important;
}

.mt-7
{
    margin-top: 7% !important;
}

.mt-8
{
    margin-top: 8% !important;
}

.mt-9
{
    margin-top: 9% !important;
}

.mt-10
{
    margin-top: 10% !important;
}

/* Margin Right */
.mr-0
{
    margin-right: 0 !important;
}

.mr-1
{
    margin-right: 1% !important;
}

.mr-2
{
    margin-right: 2% !important;
}

.mr-3
{
    margin-right: 3% !important;
}

.mr-4
{
    margin-right: 4% !important;
}

.mr-5
{
    margin-right: 5% !important;
}

.mr-6
{
    margin-right: 6% !important;
}

.mr-7
{
    margin-right: 7% !important;
}

.mr-8
{
    margin-right: 8% !important;
}

.mr-9
{
    margin-right: 9% !important;
}

.mr-10
{
    margin-right: 10% !important;
}

/* Margin Left */
.ml-0
{
    margin-left: 0 !important;
}

.ml-1
{
    margin-left: 1% !important;
}

.ml-2
{
    margin-left: 2% !important;
}

.ml-3
{
    margin-left: 3% !important;
}

.ml-4
{
    margin-left: 4% !important;
}

.ml-5
{
    margin-left: 5% !important;
}

.ml-6
{
    margin-left: 6% !important;
}

.ml-7
{
    margin-left: 7% !important;
}

.ml-8
{
    margin-left: 8% !important;
}

.ml-9
{
    margin-left: 9% !important;
}

.ml-10
{
    margin-left: 10% !important;
}

/* Margin Bottom */
.mb-0
{
    margin-bottom: 0 !important;
}

.mb-1
{
    margin-bottom: 1% !important;
}

.mb-2
{
    margin-bottom: 2% !important;
}

.mb-3
{
    margin-bottom: 3% !important;
}

.mb-4
{
    margin-bottom: 4% !important;
}

.mb-5
{
    margin-bottom: 5% !important;
}

.mb-6
{
    margin-bottom: 6% !important;
}

.mb-7
{
    margin-bottom: 7% !important;
}

.mb-8
{
    margin-bottom: 8% !important;
}

.mb-9
{
    margin-bottom: 9% !important;
}

.mb-10
{
    margin-bottom: 10% !important;
}

/* Padding All */
.padding-1
{
    padding: 1% !important;
}

.padding-2
{
    padding: 2% !important;
}

.padding-3
{
    padding: 3% !important;
}

.padding-4
{
    padding: 4% !important;
}

.padding-5
{
    padding: 5% !important;
}

.padding-6
{
    padding: 6% !important;
}

.padding-7
{
    padding: 7% !important;
}

.padding-8
{
    padding: 8% !important;
}

.padding-9
{
    padding: 9% !important;
}

.padding-10
{
    padding: 10% !important;
}

.p-0
{
    padding: 0 !important;
}

/* Padding Top */
.pt-0
{
    padding-top: 0 !important;
}

.pt-1
{
    padding-top: 1% !important;
}

.pt-2
{
    padding-top: 2% !important;
}

.pt-3
{
    padding-top: 3% !important;
}

.pt-4
{
    padding-top: 4% !important;
}

.pt-5
{
    padding-top: 5% !important;
}

.pt-6
{
    padding-top: 6% !important;
}

.pt-7
{
    padding-top: 7% !important;
}

.pt-8
{
    padding-top: 8% !important;
}

.pt-9
{
    padding-top: 9% !important;
}

.pt-10
{
    padding-top: 10% !important;
}

/* Padding Right */
.pr-0
{
    padding-right: 0 !important;
}

.pr-1
{
    padding-right: 1% !important;
}

.pr-2
{
    padding-right: 2% !important;
}

.pr-3
{
    padding-right: 3% !important;
}

.pr-4
{
    padding-right: 4% !important;
}

.pr-5
{
    padding-right: 5% !important;
}

.pr-6
{
    padding-right: 6% !important;
}

.pr-7
{
    padding-right: 7% !important;
}

.pr-8
{
    padding-right: 8% !important;
}

.pr-9
{
    padding-right: 9% !important;
}

.pr-10
{
    padding-right: 10% !important;
}

/* Padding Right */
.pl-0
{
    padding-left: 0 !important;
}

.pl-1
{
    padding-left: 1% !important;
}

.pl-2
{
    padding-left: 2% !important;
}

.pl-3
{
    padding-left: 3% !important;
}

.pl-4
{
    padding-left: 4% !important;
}

.pl-5
{
    padding-left: 5% !important;
}

.pl-6
{
    padding-left: 6% !important;
}

.pl-7
{
    padding-left: 7% !important;
}

.pl-8
{
    padding-left: 8% !important;
}

.pl-9
{
    padding-left: 9% !important;
}

.pl-10
{
    padding-left: 10% !important;
}

/* Padding Bottom */
.pb-0
{
    padding-bottom: 0 !important;
}

.pb-1
{
    padding-bottom: 1% !important;
}

.pb-2
{
    padding-bottom: 2% !important;
}

.pb-3
{
    padding-bottom: 3% !important;
}

.pb-4
{
    padding-bottom: 4% !important;
}

.pb-5
{
    padding-bottom: 5% !important;
}

.pb-6
{
    padding-bottom: 6% !important;
}

.pb-7
{
    padding-bottom: 7% !important;
}

.pb-8
{
    padding-bottom: 8% !important;
}

.pb-9
{
    padding-bottom: 9% !important;
}

.pb-10
{
    padding-bottom: 10% !important;
}

/* Minimum Height */
.min-height-100
{
    min-height: 100px !important;
}

.min-height-150
{
    min-height: 150px !important;
}

.min-height-200
{
    min-height: 200px !important;
}

.min-height-250
{
    min-height: 250px !important;
}

.min-height-300
{
    min-height: 300px !important;
}

@media (min-width: 576px)
{
    .left-align-sm
    {
        text-align: left !important;
    }
    .right-align-sm
    {
        text-align: right !important;
    }
    .center-align-sm
    {
        text-align: center !important;
    }
}

@media (min-width: 768px)
{
    .left-align-md
    {
        text-align: left !important;
    }
    .right-align-md
    {
        text-align: right !important;
    }
    .center-align-md
    {
        text-align: center !important;
    }
}

@media (min-width: 992px)
{
    .left-align-lg
    {
        text-align: left !important;
    }
    .right-align-lg
    {
        text-align: right !important;
    }
    .center-align-lg
    {
        text-align: center !important;
    }
}

@media (min-width: 1200px)
{
    .left-align-xl
    {
        text-align: left !important;
    }
    .right-align-xl
    {
        text-align: right !important;
    }
    .center-align-xl
    {
        text-align: center !important;
    }
}

.mx-auto
{
    margin-right: auto !important;
    margin-left: auto !important;
}

.text-lowercase
{
    text-transform: lowercase !important;
}

.text-uppercase
{
    text-transform: uppercase !important;
}

.text-capitalize
{
    text-transform: capitalize !important;
}

/* Icon Background Round */
.background-round
{
    padding: 15px;

    border-radius: 50%;
    background-color: rgba(0, 0, 0, .18);
}

.cursor-pointer
{
    cursor: pointer;
}

/* opacity 0 */
.opacity-0
{
    opacity: 0;
}

/* Border None & Shadow None */
.border-none
{
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
    border-left: none !important;
}

.box-shadow-none
{
    box-shadow: none !important;
}

/* Margin 0 & Padding 0 */
.no-margin
{
    margin: 0 !important;
}

.no-padding
{
    padding: 0 !important;
}

/* Position */
.position-absolute
{
    position: absolute;
}

.position-none
{
    position: unset;
}

/* Vertical Align */
.vertical-align-center
{
    position: absolute;
    top: 50%;

    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.vertical-align-bottom
{
    vertical-align: bottom;
}

.vertical-align-super
{
    vertical-align: super;
}

.vertical-text-top
{
    vertical-align: text-top !important;
}

.vertical-text-sub
{
    vertical-align: sub;
}

.vertical-text-middle
{
    vertical-align: middle;
}

.height-100vh
{
    height: 100vh;
}

/* Line Height */
.line-height-0
{
    line-height: 0 !important;
}

/* Icon Background */
.icon-bg-circle
{
    padding: .4rem;

    color: #fff;
    border-radius: 50%;
}

.text-shadow
{
    text-shadow: -1px 2px 4px black;
}

.display-none
{
    display: none;
}

.display-inline
{
    display: inline;
}

.display-block
{
    display: block;
}

.display-grid
{
    display: grid;
}

.display-flex
{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
}

.float-none
{
    float: none !important;
}

.float-left
{
    float: left !important;
}

.float-right
{
    float: right !important;
}

.float-inherit
{
    float: inherit !important;
}

.clear-both
{
    clear: both;
}

ul.list-type-bullet
{
    padding-left: 40px;

    list-style-type: inherit;
}

ul.list-type-bullet li
{
    list-style-type: inherit;
}

.justify-content-end
{
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.justify-content-between
{
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.justify-content-center
{
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.align-items-center
{
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.flex-grow-1
{
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.flex-wrap
{
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.flex-column
{
    flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
}

.text-ellipsis
{
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-nowrap
{
    white-space: nowrap;
}

/*---------------------------------
    Typography
-----------------------------------*/
a
{
    text-decoration: none;
}

html
{
    font-family: 'Nunito', sans-serif;
    font-weight: normal;
    line-height: 1.5;

    color: #6b6f82;
}

@media only screen and (min-width: 0)
{
    html
    {
        font-size: 14px;
    }
}

@media only screen and (min-width: 992px)
{
    html
    {
        font-size: 14.5px;
    }
}

@media only screen and (min-width: 1200px)
{
    html
    {
        font-size: 15px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6
{
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    line-height: 1.1;

    color: #333;
}

p
{
    font-family: 'Nunito', sans-serif;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a
{
    font-weight: inherit;
}

h1
{
    font-size: 4.2rem;
    line-height: 110%;

    margin: 2.1rem 0 1.68rem 0;
}

h2
{
    font-size: 3.56rem;
    line-height: 110%;

    margin: 1.78rem 0 1.424rem 0;
}

h3
{
    font-size: 2.92rem;
    line-height: 110%;

    margin: 1.46rem 0 1.168rem 0;
}

h4
{
    font-size: 2.28rem;
    line-height: 110%;

    margin: 1.14rem 0 .912rem 0;
}

h5
{
    font-size: 1.64rem;
    line-height: 110%;

    margin: .82rem 0 .656rem 0;
}

h6
{
    font-size: 1.15rem;
    line-height: 110%;

    margin: .575rem 0 .46rem 0;
}

em
{
    font-style: italic;
}

strong
{
    font-weight: 500;
}

small
{
    font-size: 75%;
}

.light
{
    font-weight: 300;
}

.thin
{
    font-weight: 200;
}

.flow-text
{
    font-weight: 300;
}

@media only screen and (min-width: 360px)
{
    .flow-text
    {
        font-size: 1.2rem;
    }
}

@media only screen and (min-width: 390px)
{
    .flow-text
    {
        font-size: 1.224rem;
    }
}

@media only screen and (min-width: 420px)
{
    .flow-text
    {
        font-size: 1.248rem;
    }
}

@media only screen and (min-width: 450px)
{
    .flow-text
    {
        font-size: 1.272rem;
    }
}

@media only screen and (min-width: 480px)
{
    .flow-text
    {
        font-size: 1.296rem;
    }
}

@media only screen and (min-width: 510px)
{
    .flow-text
    {
        font-size: 1.32rem;
    }
}

@media only screen and (min-width: 540px)
{
    .flow-text
    {
        font-size: 1.344rem;
    }
}

@media only screen and (min-width: 570px)
{
    .flow-text
    {
        font-size: 1.368rem;
    }
}

@media only screen and (min-width: 600px)
{
    .flow-text
    {
        font-size: 1.392rem;
    }
}

@media only screen and (min-width: 630px)
{
    .flow-text
    {
        font-size: 1.416rem;
    }
}

@media only screen and (min-width: 660px)
{
    .flow-text
    {
        font-size: 1.44rem;
    }
}

@media only screen and (min-width: 690px)
{
    .flow-text
    {
        font-size: 1.464rem;
    }
}

@media only screen and (min-width: 720px)
{
    .flow-text
    {
        font-size: 1.488rem;
    }
}

@media only screen and (min-width: 750px)
{
    .flow-text
    {
        font-size: 1.512rem;
    }
}

@media only screen and (min-width: 780px)
{
    .flow-text
    {
        font-size: 1.536rem;
    }
}

@media only screen and (min-width: 810px)
{
    .flow-text
    {
        font-size: 1.56rem;
    }
}

@media only screen and (min-width: 840px)
{
    .flow-text
    {
        font-size: 1.584rem;
    }
}

@media only screen and (min-width: 870px)
{
    .flow-text
    {
        font-size: 1.608rem;
    }
}

@media only screen and (min-width: 900px)
{
    .flow-text
    {
        font-size: 1.632rem;
    }
}

@media only screen and (min-width: 930px)
{
    .flow-text
    {
        font-size: 1.656rem;
    }
}

@media only screen and (min-width: 960px)
{
    .flow-text
    {
        font-size: 1.68rem;
    }
}

@media only screen and (max-width: 360px)
{
    .flow-text
    {
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 768px)
{
    .nav-expanded .brand-sidebar
    {
        background: transparent !important;
    }
    .brand-sidebar .logo-wrapper
    {
        padding: 17px 18px !important;

        text-align: center;

        background: transparent;
    }
}

.small
{
    font-size: 1.0rem !important;
}

.medium-small
{
    font-size: .9rem !important;
}

.ultra-small
{
    font-size: .8rem !important;
}

small
{
    font-size: .8rem;
}

.strong
{
    font-weight: 600;
}

h4.header
{
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.508rem;

    margin: 1.14rem 0 .912rem 0;

    text-transform: uppercase;
}

h4.header2
{
    font-size: 1.1rem;
    font-weight: 400;

    text-transform: uppercase;
}

p.title
{
    font-size: 1.3rem;
}

p.header
{
    font-size: 1rem;
    font-weight: 500;

    text-transform: uppercase;
}

li.li-hover:hover
{
    background: transparent !important;
}

li.search-out:hover
{
    background: transparent;
}

li.mobile:before
{
    background: #f7464a;
}

li.kitchen:before
{
    background: #46bfbd;
}

li.home:before
{
    background: #fdb45c;
}

.more-text
{
    font-weight: 500;

    padding: 5px 20px;

    letter-spacing: 1px;
    text-transform: uppercase;
}

.valign-demo
{
    height: 400px;

    background-color: #ddd;
}

.margin
{
    margin: 0 !important;
}

.uppercase
{
    text-transform: uppercase;
}

/*----------------------------------------
  Grid
------------------------------------------*/
.section
{
    padding: .5rem 0;
}


.materialize-red
{
    background-color: #e51c23 !important;
}

.materialize-red-text
{
    color: #e51c23 !important;
}

.materialize-red.lighten-5
{
    background-color: #fdeaeb !important;
}

.materialize-red-text.text-lighten-5
{
    color: #fdeaeb !important;
}

.materialize-red.lighten-4
{
    background-color: #f8c1c3 !important;
}

.materialize-red-text.text-lighten-4
{
    color: #f8c1c3 !important;
}

.materialize-red.lighten-3
{
    background-color: #f3989b !important;
}

.materialize-red-text.text-lighten-3
{
    color: #f3989b !important;
}

.materialize-red.lighten-2
{
    background-color: #ee6e73 !important;
}

.materialize-red-text.text-lighten-2
{
    color: #ee6e73 !important;
}

.materialize-red.lighten-1
{
    background-color: #ea454b !important;
}

.materialize-red-text.text-lighten-1
{
    color: #ea454b !important;
}

.materialize-red.darken-1
{
    background-color: #d0181e !important;
}

.materialize-red-text.text-darken-1
{
    color: #d0181e !important;
}

.materialize-red.darken-2
{
    background-color: #b9151b !important;
}

.materialize-red-text.text-darken-2
{
    color: #b9151b !important;
}

.materialize-red.darken-3
{
    background-color: #a21318 !important;
}

.materialize-red-text.text-darken-3
{
    color: #a21318 !important;
}

.materialize-red.darken-4
{
    background-color: #8b1014 !important;
}

.materialize-red-text.text-darken-4
{
    color: #8b1014 !important;
}

.red
{
    background-color: #f44336 !important;
}

.red-text
{
    color: #f44336 !important;
}

.red.lighten-5
{
    background-color: #ffebee !important;
}

.red-text.text-lighten-5
{
    color: #ffebee !important;
}

.red.lighten-4
{
    background-color: #ffcdd2 !important;
}

.red-text.text-lighten-4
{
    color: #ffcdd2 !important;
}

.red.lighten-3
{
    background-color: #ef9a9a !important;
}

.red-text.text-lighten-3
{
    color: #ef9a9a !important;
}

.red.lighten-2
{
    background-color: #e57373 !important;
}

.red-text.text-lighten-2
{
    color: #e57373 !important;
}

.red.lighten-1
{
    background-color: #ef5350 !important;
}

.red-text.text-lighten-1
{
    color: #ef5350 !important;
}

.red.darken-1
{
    background-color: #e53935 !important;
}

.red-text.text-darken-1
{
    color: #e53935 !important;
}

.red.darken-2
{
    background-color: #d32f2f !important;
}

.red-text.text-darken-2
{
    color: #d32f2f !important;
}

.red.darken-3
{
    background-color: #c62828 !important;
}

.red-text.text-darken-3
{
    color: #c62828 !important;
}

.red.darken-4
{
    background-color: #b71c1c !important;
}

.red-text.text-darken-4
{
    color: #b71c1c !important;
}

.red.accent-1
{
    background-color: #ff8a80 !important;
}

.red-text.text-accent-1
{
    color: #ff8a80 !important;
}

.red.accent-2
{
    background-color: #ff5252 !important;
}

.red-text.text-accent-2
{
    color: #ff5252 !important;
}

.red.accent-3
{
    background-color: #ff1744 !important;
}

.red-text.text-accent-3
{
    color: #ff1744 !important;
}

.red.accent-4
{
    background-color: #d50000 !important;
}

.red-text.text-accent-4
{
    color: #d50000 !important;
}

.pink
{
    background-color: #e91e63 !important;
}

.pink-text
{
    color: #e91e63 !important;
}

.pink.lighten-5
{
    background-color: #fce4ec !important;
}

.pink-text.text-lighten-5
{
    color: #fce4ec !important;
}

.pink.lighten-4
{
    background-color: #f8bbd0 !important;
}

.pink-text.text-lighten-4
{
    color: #f8bbd0 !important;
}

.pink.lighten-3
{
    background-color: #f48fb1 !important;
}

.pink-text.text-lighten-3
{
    color: #f48fb1 !important;
}

.pink.lighten-2
{
    background-color: #f06292 !important;
}

.pink-text.text-lighten-2
{
    color: #f06292 !important;
}

.pink.lighten-1
{
    background-color: #ec407a !important;
}

.pink-text.text-lighten-1
{
    color: #ec407a !important;
}

.pink.darken-1
{
    background-color: #d81b60 !important;
}

.pink-text.text-darken-1
{
    color: #d81b60 !important;
}

.pink.darken-2
{
    background-color: #c2185b !important;
}

.pink-text.text-darken-2
{
    color: #c2185b !important;
}

.pink.darken-3
{
    background-color: #ad1457 !important;
}

.pink-text.text-darken-3
{
    color: #ad1457 !important;
}

.pink.darken-4
{
    background-color: #880e4f !important;
}

.pink-text.text-darken-4
{
    color: #880e4f !important;
}

.pink.accent-1
{
    background-color: #ff80ab !important;
}

.pink-text.text-accent-1
{
    color: #ff80ab !important;
}

.pink.accent-2
{
    background-color: #ff4081 !important;
}

.pink-text.text-accent-2
{
    color: #ff4081 !important;
}

.pink.accent-3
{
    background-color: #f50057 !important;
}

.pink-text.text-accent-3
{
    color: #f50057 !important;
}

.pink.accent-4
{
    background-color: #c51162 !important;
}

.pink-text.text-accent-4
{
    color: #c51162 !important;
}

.purple
{
    background-color: #9c27b0 !important;
}

.purple-text
{
    color: #9c27b0 !important;
}

.purple.lighten-5
{
    background-color: #f3e5f5 !important;
}

.purple-text.text-lighten-5
{
    color: #f3e5f5 !important;
}

.purple.lighten-4
{
    background-color: #e1bee7 !important;
}

.purple-text.text-lighten-4
{
    color: #e1bee7 !important;
}

.purple.lighten-3
{
    background-color: #ce93d8 !important;
}

.purple-text.text-lighten-3
{
    color: #ce93d8 !important;
}

.purple.lighten-2
{
    background-color: #ba68c8 !important;
}

.purple-text.text-lighten-2
{
    color: #ba68c8 !important;
}

.purple.lighten-1
{
    background-color: #ab47bc !important;
}

.purple-text.text-lighten-1
{
    color: #ab47bc !important;
}

.purple.darken-1
{
    background-color: #8e24aa !important;
}

.purple-text.text-darken-1
{
    color: #8e24aa !important;
}

.purple.darken-2
{
    background-color: #7b1fa2 !important;
}

.purple-text.text-darken-2
{
    color: #7b1fa2 !important;
}

.purple.darken-3
{
    background-color: #6a1b9a !important;
}

.purple-text.text-darken-3
{
    color: #6a1b9a !important;
}

.purple.darken-4
{
    background-color: #4a148c !important;
}

.purple-text.text-darken-4
{
    color: #4a148c !important;
}

.purple.accent-1
{
    background-color: #ea80fc !important;
}

.purple-text.text-accent-1
{
    color: #ea80fc !important;
}

.purple.accent-2
{
    background-color: #e040fb !important;
}

.purple-text.text-accent-2
{
    color: #e040fb !important;
}

.purple.accent-3
{
    background-color: #d500f9 !important;
}

.purple-text.text-accent-3
{
    color: #d500f9 !important;
}

.purple.accent-4
{
    background-color: #a0f !important;
}

.purple-text.text-accent-4
{
    color: #a0f !important;
}

.deep-purple
{
    background-color: #673ab7 !important;
}

.deep-purple-text
{
    color: #673ab7 !important;
}

.deep-purple.lighten-5
{
    background-color: #ede7f6 !important;
}

.deep-purple-text.text-lighten-5
{
    color: #ede7f6 !important;
}

.deep-purple.lighten-4
{
    background-color: #d1c4e9 !important;
}

.deep-purple-text.text-lighten-4
{
    color: #d1c4e9 !important;
}

.deep-purple.lighten-3
{
    background-color: #b39ddb !important;
}

.deep-purple-text.text-lighten-3
{
    color: #b39ddb !important;
}

.deep-purple.lighten-2
{
    background-color: #9575cd !important;
}

.deep-purple-text.text-lighten-2
{
    color: #9575cd !important;
}

.deep-purple.lighten-1
{
    background-color: #7e57c2 !important;
}

.deep-purple-text.text-lighten-1
{
    color: #7e57c2 !important;
}

.deep-purple.darken-1
{
    background-color: #5e35b1 !important;
}

.deep-purple-text.text-darken-1
{
    color: #5e35b1 !important;
}

.deep-purple.darken-2
{
    background-color: #512da8 !important;
}

.deep-purple-text.text-darken-2
{
    color: #512da8 !important;
}

.deep-purple.darken-3
{
    background-color: #4527a0 !important;
}

.deep-purple-text.text-darken-3
{
    color: #4527a0 !important;
}

.deep-purple.darken-4
{
    background-color: #311b92 !important;
}

.deep-purple-text.text-darken-4
{
    color: #311b92 !important;
}

.deep-purple.accent-1
{
    background-color: #b388ff !important;
}

.deep-purple-text.text-accent-1
{
    color: #b388ff !important;
}

.deep-purple.accent-2
{
    background-color: #7c4dff !important;
}

.deep-purple-text.text-accent-2
{
    color: #7c4dff !important;
}

.deep-purple.accent-3
{
    background-color: #651fff !important;
}

.deep-purple-text.text-accent-3
{
    color: #651fff !important;
}

.deep-purple.accent-4
{
    background-color: #6200ea !important;
}

.deep-purple-text.text-accent-4
{
    color: #6200ea !important;
}

.indigo
{
    background-color: #3f51b5 !important;
}

.indigo-text
{
    color: #3f51b5 !important;
}

.indigo.lighten-5
{
    background-color: #e8eaf6 !important;
}

.indigo-text.text-lighten-5
{
    color: #e8eaf6 !important;
}

.indigo.lighten-4
{
    background-color: #c5cae9 !important;
}

.indigo-text.text-lighten-4
{
    color: #c5cae9 !important;
}

.indigo.lighten-3
{
    background-color: #9fa8da !important;
}

.indigo-text.text-lighten-3
{
    color: #9fa8da !important;
}

.indigo.lighten-2
{
    background-color: #7986cb !important;
}

.indigo-text.text-lighten-2
{
    color: #7986cb !important;
}

.indigo.lighten-1
{
    background-color: #5c6bc0 !important;
}

.indigo-text.text-lighten-1
{
    color: #5c6bc0 !important;
}

.indigo.darken-1
{
    background-color: #3949ab !important;
}

.indigo-text.text-darken-1
{
    color: #3949ab !important;
}

.indigo.darken-2
{
    background-color: #303f9f !important;
}

.indigo-text.text-darken-2
{
    color: #303f9f !important;
}

.indigo.darken-3
{
    background-color: #283593 !important;
}

.indigo-text.text-darken-3
{
    color: #283593 !important;
}

.indigo.darken-4
{
    background-color: #1a237e !important;
}

.indigo-text.text-darken-4
{
    color: #1a237e !important;
}

.indigo.accent-1
{
    background-color: #8c9eff !important;
}

.indigo-text.text-accent-1
{
    color: #8c9eff !important;
}

.indigo.accent-2
{
    background-color: #536dfe !important;
}

.indigo-text.text-accent-2
{
    color: #536dfe !important;
}

.indigo.accent-3
{
    background-color: #3d5afe !important;
}

.indigo-text.text-accent-3
{
    color: #3d5afe !important;
}

.indigo.accent-4
{
    background-color: #304ffe !important;
}

.indigo-text.text-accent-4
{
    color: #304ffe !important;
}

.blue
{
    background-color: #2196f3 !important;
}

.blue-text
{
    color: #2196f3 !important;
}

.blue.lighten-5
{
    background-color: #e3f2fd !important;
}

.blue-text.text-lighten-5
{
    color: #e3f2fd !important;
}

.blue.lighten-4
{
    background-color: #bbdefb !important;
}

.blue-text.text-lighten-4
{
    color: #bbdefb !important;
}

.blue.lighten-3
{
    background-color: #90caf9 !important;
}

.blue-text.text-lighten-3
{
    color: #90caf9 !important;
}

.blue.lighten-2
{
    background-color: #64b5f6 !important;
}

.blue-text.text-lighten-2
{
    color: #64b5f6 !important;
}

.blue.lighten-1
{
    background-color: #42a5f5 !important;
}

.blue-text.text-lighten-1
{
    color: #42a5f5 !important;
}

.blue.darken-1
{
    background-color: #1e88e5 !important;
}

.blue-text.text-darken-1
{
    color: #1e88e5 !important;
}

.blue.darken-2
{
    background-color: #1976d2 !important;
}

.blue-text.text-darken-2
{
    color: #1976d2 !important;
}

.blue.darken-3
{
    background-color: #1565c0 !important;
}

.blue-text.text-darken-3
{
    color: #1565c0 !important;
}

.blue.darken-4
{
    background-color: #0d47a1 !important;
}

.blue-text.text-darken-4
{
    color: #0d47a1 !important;
}

.blue.accent-1
{
    background-color: #82b1ff !important;
}

.blue-text.text-accent-1
{
    color: #82b1ff !important;
}

.blue.accent-2
{
    background-color: #448aff !important;
}

.blue-text.text-accent-2
{
    color: #448aff !important;
}

.blue.accent-3
{
    background-color: #2979ff !important;
}

.blue-text.text-accent-3
{
    color: #2979ff !important;
}

.blue.accent-4
{
    background-color: #2962ff !important;
}

.blue-text.text-accent-4
{
    color: #2962ff !important;
}

.light-blue
{
    background-color: #03a9f4 !important;
}

.light-blue-text
{
    color: #03a9f4 !important;
}

.light-blue.lighten-5
{
    background-color: #e1f5fe !important;
}

.light-blue-text.text-lighten-5
{
    color: #e1f5fe !important;
}

.light-blue.lighten-4
{
    background-color: #b3e5fc !important;
}

.light-blue-text.text-lighten-4
{
    color: #b3e5fc !important;
}

.light-blue.lighten-3
{
    background-color: #81d4fa !important;
}

.light-blue-text.text-lighten-3
{
    color: #81d4fa !important;
}

.light-blue.lighten-2
{
    background-color: #4fc3f7 !important;
}

.light-blue-text.text-lighten-2
{
    color: #4fc3f7 !important;
}

.light-blue.lighten-1
{
    background-color: #29b6f6 !important;
}

.light-blue-text.text-lighten-1
{
    color: #29b6f6 !important;
}

.light-blue.darken-1
{
    background-color: #039be5 !important;
}

.light-blue-text.text-darken-1
{
    color: #039be5 !important;
}

.light-blue.darken-2
{
    background-color: #0288d1 !important;
}

.light-blue-text.text-darken-2
{
    color: #0288d1 !important;
}

.light-blue.darken-3
{
    background-color: #0277bd !important;
}

.light-blue-text.text-darken-3
{
    color: #0277bd !important;
}

.light-blue.darken-4
{
    background-color: #01579b !important;
}

.light-blue-text.text-darken-4
{
    color: #01579b !important;
}

.light-blue.accent-1
{
    background-color: #80d8ff !important;
}

.light-blue-text.text-accent-1
{
    color: #80d8ff !important;
}

.light-blue.accent-2
{
    background-color: #40c4ff !important;
}

.light-blue-text.text-accent-2
{
    color: #40c4ff !important;
}

.light-blue.accent-3
{
    background-color: #00b0ff !important;
}

.light-blue-text.text-accent-3
{
    color: #00b0ff !important;
}

.light-blue.accent-4
{
    background-color: #0091ea !important;
}

.light-blue-text.text-accent-4
{
    color: #0091ea !important;
}

.cyan
{
    background-color: #00bcd4 !important;
}

.cyan-text
{
    color: #00bcd4 !important;
}

.cyan.lighten-5
{
    background-color: #e0f7fa !important;
}

.cyan-text.text-lighten-5
{
    color: #e0f7fa !important;
}

.cyan.lighten-4
{
    background-color: #b2ebf2 !important;
}

.cyan-text.text-lighten-4
{
    color: #b2ebf2 !important;
}

.cyan.lighten-3
{
    background-color: #80deea !important;
}

.cyan-text.text-lighten-3
{
    color: #80deea !important;
}

.cyan.lighten-2
{
    background-color: #4dd0e1 !important;
}

.cyan-text.text-lighten-2
{
    color: #4dd0e1 !important;
}

.cyan.lighten-1
{
    background-color: #26c6da !important;
}

.cyan-text.text-lighten-1
{
    color: #26c6da !important;
}

.cyan.darken-1
{
    background-color: #00acc1 !important;
}

.cyan-text.text-darken-1
{
    color: #00acc1 !important;
}

.cyan.darken-2
{
    background-color: #0097a7 !important;
}

.cyan-text.text-darken-2
{
    color: #0097a7 !important;
}

.cyan.darken-3
{
    background-color: #00838f !important;
}

.cyan-text.text-darken-3
{
    color: #00838f !important;
}

.cyan.darken-4
{
    background-color: #006064 !important;
}

.cyan-text.text-darken-4
{
    color: #006064 !important;
}

.cyan.accent-1
{
    background-color: #84ffff !important;
}

.cyan-text.text-accent-1
{
    color: #84ffff !important;
}

.cyan.accent-2
{
    background-color: #18ffff !important;
}

.cyan-text.text-accent-2
{
    color: #18ffff !important;
}

.cyan.accent-3
{
    background-color: #00e5ff !important;
}

.cyan-text.text-accent-3
{
    color: #00e5ff !important;
}

.cyan.accent-4
{
    background-color: #00b8d4 !important;
}

.cyan-text.text-accent-4
{
    color: #00b8d4 !important;
}

.teal
{
    background-color: #009688 !important;
}

.teal-text
{
    color: #009688 !important;
}

.teal.lighten-5
{
    background-color: #e0f2f1 !important;
}

.teal-text.text-lighten-5
{
    color: #e0f2f1 !important;
}

.teal.lighten-4
{
    background-color: #b2dfdb !important;
}

.teal-text.text-lighten-4
{
    color: #b2dfdb !important;
}

.teal.lighten-3
{
    background-color: #80cbc4 !important;
}

.teal-text.text-lighten-3
{
    color: #80cbc4 !important;
}

.teal.lighten-2
{
    background-color: #4db6ac !important;
}

.teal-text.text-lighten-2
{
    color: #4db6ac !important;
}

.teal.lighten-1
{
    background-color: #26a69a !important;
}

.teal-text.text-lighten-1
{
    color: #26a69a !important;
}

.teal.darken-1
{
    background-color: #00897b !important;
}

.teal-text.text-darken-1
{
    color: #00897b !important;
}

.teal.darken-2
{
    background-color: #00796b !important;
}

.teal-text.text-darken-2
{
    color: #00796b !important;
}

.teal.darken-3
{
    background-color: #00695c !important;
}

.teal-text.text-darken-3
{
    color: #00695c !important;
}

.teal.darken-4
{
    background-color: #004d40 !important;
}

.teal-text.text-darken-4
{
    color: #004d40 !important;
}

.teal.accent-1
{
    background-color: #a7ffeb !important;
}

.teal-text.text-accent-1
{
    color: #a7ffeb !important;
}

.teal.accent-2
{
    background-color: #64ffda !important;
}

.teal-text.text-accent-2
{
    color: #64ffda !important;
}

.teal.accent-3
{
    background-color: #1de9b6 !important;
}

.teal-text.text-accent-3
{
    color: #1de9b6 !important;
}

.teal.accent-4
{
    background-color: #00bfa5 !important;
}

.teal-text.text-accent-4
{
    color: #00bfa5 !important;
}

.green
{
    background-color: #4caf50 !important;
}

.green-text
{
    color: #4caf50 !important;
}

.green.lighten-5
{
    background-color: #e8f5e9 !important;
}

.green-text.text-lighten-5
{
    color: #e8f5e9 !important;
}

.green.lighten-4
{
    background-color: #c8e6c9 !important;
}

.green-text.text-lighten-4
{
    color: #c8e6c9 !important;
}

.green.lighten-3
{
    background-color: #a5d6a7 !important;
}

.green-text.text-lighten-3
{
    color: #a5d6a7 !important;
}

.green.lighten-2
{
    background-color: #81c784 !important;
}

.green-text.text-lighten-2
{
    color: #81c784 !important;
}

.green.lighten-1
{
    background-color: #66bb6a !important;
}

.green-text.text-lighten-1
{
    color: #66bb6a !important;
}

.green.darken-1
{
    background-color: #43a047 !important;
}

.green-text.text-darken-1
{
    color: #43a047 !important;
}

.green.darken-2
{
    background-color: #388e3c !important;
}

.green-text.text-darken-2
{
    color: #388e3c !important;
}

.green.darken-3
{
    background-color: #2e7d32 !important;
}

.green-text.text-darken-3
{
    color: #2e7d32 !important;
}

.green.darken-4
{
    background-color: #1b5e20 !important;
}

.green-text.text-darken-4
{
    color: #1b5e20 !important;
}

.green.accent-1
{
    background-color: #b9f6ca !important;
}

.green-text.text-accent-1
{
    color: #b9f6ca !important;
}

.green.accent-2
{
    background-color: #69f0ae !important;
}

.green-text.text-accent-2
{
    color: #69f0ae !important;
}

.green.accent-3
{
    background-color: #00e676 !important;
}

.green-text.text-accent-3
{
    color: #00e676 !important;
}

.green.accent-4
{
    background-color: #00c853 !important;
}

.green-text.text-accent-4
{
    color: #00c853 !important;
}

.light-green
{
    background-color: #8bc34a !important;
}

.light-green-text
{
    color: #8bc34a !important;
}

.light-green.lighten-5
{
    background-color: #f1f8e9 !important;
}

.light-green-text.text-lighten-5
{
    color: #f1f8e9 !important;
}

.light-green.lighten-4
{
    background-color: #dcedc8 !important;
}

.light-green-text.text-lighten-4
{
    color: #dcedc8 !important;
}

.light-green.lighten-3
{
    background-color: #c5e1a5 !important;
}

.light-green-text.text-lighten-3
{
    color: #c5e1a5 !important;
}

.light-green.lighten-2
{
    background-color: #aed581 !important;
}

.light-green-text.text-lighten-2
{
    color: #aed581 !important;
}

.light-green.lighten-1
{
    background-color: #9ccc65 !important;
}

.light-green-text.text-lighten-1
{
    color: #9ccc65 !important;
}

.light-green.darken-1
{
    background-color: #7cb342 !important;
}

.light-green-text.text-darken-1
{
    color: #7cb342 !important;
}

.light-green.darken-2
{
    background-color: #689f38 !important;
}

.light-green-text.text-darken-2
{
    color: #689f38 !important;
}

.light-green.darken-3
{
    background-color: #558b2f !important;
}

.light-green-text.text-darken-3
{
    color: #558b2f !important;
}

.light-green.darken-4
{
    background-color: #33691e !important;
}

.light-green-text.text-darken-4
{
    color: #33691e !important;
}

.light-green.accent-1
{
    background-color: #ccff90 !important;
}

.light-green-text.text-accent-1
{
    color: #ccff90 !important;
}

.light-green.accent-2
{
    background-color: #b2ff59 !important;
}

.light-green-text.text-accent-2
{
    color: #b2ff59 !important;
}

.light-green.accent-3
{
    background-color: #76ff03 !important;
}

.light-green-text.text-accent-3
{
    color: #76ff03 !important;
}

.light-green.accent-4
{
    background-color: #64dd17 !important;
}

.light-green-text.text-accent-4
{
    color: #64dd17 !important;
}

.lime
{
    background-color: #cddc39 !important;
}

.lime-text
{
    color: #cddc39 !important;
}

.lime.lighten-5
{
    background-color: #f9fbe7 !important;
}

.lime-text.text-lighten-5
{
    color: #f9fbe7 !important;
}

.lime.lighten-4
{
    background-color: #f0f4c3 !important;
}

.lime-text.text-lighten-4
{
    color: #f0f4c3 !important;
}

.lime.lighten-3
{
    background-color: #e6ee9c !important;
}

.lime-text.text-lighten-3
{
    color: #e6ee9c !important;
}

.lime.lighten-2
{
    background-color: #dce775 !important;
}

.lime-text.text-lighten-2
{
    color: #dce775 !important;
}

.lime.lighten-1
{
    background-color: #d4e157 !important;
}

.lime-text.text-lighten-1
{
    color: #d4e157 !important;
}

.lime.darken-1
{
    background-color: #c0ca33 !important;
}

.lime-text.text-darken-1
{
    color: #c0ca33 !important;
}

.lime.darken-2
{
    background-color: #afb42b !important;
}

.lime-text.text-darken-2
{
    color: #afb42b !important;
}

.lime.darken-3
{
    background-color: #9e9d24 !important;
}

.lime-text.text-darken-3
{
    color: #9e9d24 !important;
}

.lime.darken-4
{
    background-color: #827717 !important;
}

.lime-text.text-darken-4
{
    color: #827717 !important;
}

.lime.accent-1
{
    background-color: #f4ff81 !important;
}

.lime-text.text-accent-1
{
    color: #f4ff81 !important;
}

.lime.accent-2
{
    background-color: #eeff41 !important;
}

.lime-text.text-accent-2
{
    color: #eeff41 !important;
}

.lime.accent-3
{
    background-color: #c6ff00 !important;
}

.lime-text.text-accent-3
{
    color: #c6ff00 !important;
}

.lime.accent-4
{
    background-color: #aeea00 !important;
}

.lime-text.text-accent-4
{
    color: #aeea00 !important;
}

.yellow
{
    background-color: #ffeb3b !important;
}

.yellow-text
{
    color: #ffeb3b !important;
}

.yellow.lighten-5
{
    background-color: #fffde7 !important;
}

.yellow-text.text-lighten-5
{
    color: #fffde7 !important;
}

.yellow.lighten-4
{
    background-color: #fff9c4 !important;
}

.yellow-text.text-lighten-4
{
    color: #fff9c4 !important;
}

.yellow.lighten-3
{
    background-color: #fff59d !important;
}

.yellow-text.text-lighten-3
{
    color: #fff59d !important;
}

.yellow.lighten-2
{
    background-color: #fff176 !important;
}

.yellow-text.text-lighten-2
{
    color: #fff176 !important;
}

.yellow.lighten-1
{
    background-color: #ffee58 !important;
}

.yellow-text.text-lighten-1
{
    color: #ffee58 !important;
}

.yellow.darken-1
{
    background-color: #fdd835 !important;
}

.yellow-text.text-darken-1
{
    color: #fdd835 !important;
}

.yellow.darken-2
{
    background-color: #fbc02d !important;
}

.yellow-text.text-darken-2
{
    color: #fbc02d !important;
}

.yellow.darken-3
{
    background-color: #f9a825 !important;
}

.yellow-text.text-darken-3
{
    color: #f9a825 !important;
}

.yellow.darken-4
{
    background-color: #f57f17 !important;
}

.yellow-text.text-darken-4
{
    color: #f57f17 !important;
}

.yellow.accent-1
{
    background-color: #ffff8d !important;
}

.yellow-text.text-accent-1
{
    color: #ffff8d !important;
}

.yellow.accent-2
{
    background-color: #ff0 !important;
}

.yellow-text.text-accent-2
{
    color: #ff0 !important;
}

.yellow.accent-3
{
    background-color: #ffea00 !important;
}

.yellow-text.text-accent-3
{
    color: #ffea00 !important;
}

.yellow.accent-4
{
    background-color: #ffd600 !important;
}

.yellow-text.text-accent-4
{
    color: #ffd600 !important;
}

.amber
{
    background-color: #ffc107 !important;
}

.amber-text
{
    color: #ffc107 !important;
}

.amber.lighten-5
{
    background-color: #fff8e1 !important;
}

.amber-text.text-lighten-5
{
    color: #fff8e1 !important;
}

.amber.lighten-4
{
    background-color: #ffecb3 !important;
}

.amber-text.text-lighten-4
{
    color: #ffecb3 !important;
}

.amber.lighten-3
{
    background-color: #ffe082 !important;
}

.amber-text.text-lighten-3
{
    color: #ffe082 !important;
}

.amber.lighten-2
{
    background-color: #ffd54f !important;
}

.amber-text.text-lighten-2
{
    color: #ffd54f !important;
}

.amber.lighten-1
{
    background-color: #ffca28 !important;
}

.amber-text.text-lighten-1
{
    color: #ffca28 !important;
}

.amber.darken-1
{
    background-color: #ffb300 !important;
}

.amber-text.text-darken-1
{
    color: #ffb300 !important;
}

.amber.darken-2
{
    background-color: #ffa000 !important;
}

.amber-text.text-darken-2
{
    color: #ffa000 !important;
}

.amber.darken-3
{
    background-color: #ff8f00 !important;
}

.amber-text.text-darken-3
{
    color: #ff8f00 !important;
}

.amber.darken-4
{
    background-color: #ff6f00 !important;
}

.amber-text.text-darken-4
{
    color: #ff6f00 !important;
}

.amber.accent-1
{
    background-color: #ffe57f !important;
}

.amber-text.text-accent-1
{
    color: #ffe57f !important;
}

.amber.accent-2
{
    background-color: #ffd740 !important;
}

.amber-text.text-accent-2
{
    color: #ffd740 !important;
}

.amber.accent-3
{
    background-color: #ffc400 !important;
}

.amber-text.text-accent-3
{
    color: #ffc400 !important;
}

.amber.accent-4
{
    background-color: #ffab00 !important;
}

.amber-text.text-accent-4
{
    color: #ffab00 !important;
}

.orange
{
    background-color: #ff9800 !important;
}

.orange-text
{
    color: #ff9800 !important;
}

.orange.lighten-5
{
    background-color: #fff3e0 !important;
}

.orange-text.text-lighten-5
{
    color: #fff3e0 !important;
}

.orange.lighten-4
{
    background-color: #ffe0b2 !important;
}

.orange-text.text-lighten-4
{
    color: #ffe0b2 !important;
}

.orange.lighten-3
{
    background-color: #ffcc80 !important;
}

.orange-text.text-lighten-3
{
    color: #ffcc80 !important;
}

.orange.lighten-2
{
    background-color: #ffb74d !important;
}

.orange-text.text-lighten-2
{
    color: #ffb74d !important;
}

.orange.lighten-1
{
    background-color: #ffa726 !important;
}

.orange-text.text-lighten-1
{
    color: #ffa726 !important;
}

.orange.darken-1
{
    background-color: #fb8c00 !important;
}

.orange-text.text-darken-1
{
    color: #fb8c00 !important;
}

.orange.darken-2
{
    background-color: #f57c00 !important;
}

.orange-text.text-darken-2
{
    color: #f57c00 !important;
}

.orange.darken-3
{
    background-color: #ef6c00 !important;
}

.orange-text.text-darken-3
{
    color: #ef6c00 !important;
}

.orange.darken-4
{
    background-color: #e65100 !important;
}

.orange-text.text-darken-4
{
    color: #e65100 !important;
}

.orange.accent-1
{
    background-color: #ffd180 !important;
}

.orange-text.text-accent-1
{
    color: #ffd180 !important;
}

.orange.accent-2
{
    background-color: #ffab40 !important;
}

.orange-text.text-accent-2
{
    color: #ffab40 !important;
}

.orange.accent-3
{
    background-color: #ff9100 !important;
}

.orange-text.text-accent-3
{
    color: #ff9100 !important;
}

.orange.accent-4
{
    background-color: #ff6d00 !important;
}

.orange-text.text-accent-4
{
    color: #ff6d00 !important;
}

.deep-orange
{
    background-color: #ff5722 !important;
}

.deep-orange-text
{
    color: #ff5722 !important;
}

.deep-orange.lighten-5
{
    background-color: #fbe9e7 !important;
}

.deep-orange-text.text-lighten-5
{
    color: #fbe9e7 !important;
}

.deep-orange.lighten-4
{
    background-color: #ffccbc !important;
}

.deep-orange-text.text-lighten-4
{
    color: #ffccbc !important;
}

.deep-orange.lighten-3
{
    background-color: #ffab91 !important;
}

.deep-orange-text.text-lighten-3
{
    color: #ffab91 !important;
}

.deep-orange.lighten-2
{
    background-color: #ff8a65 !important;
}

.deep-orange-text.text-lighten-2
{
    color: #ff8a65 !important;
}

.deep-orange.lighten-1
{
    background-color: #ff7043 !important;
}

.deep-orange-text.text-lighten-1
{
    color: #ff7043 !important;
}

.deep-orange.darken-1
{
    background-color: #f4511e !important;
}

.deep-orange-text.text-darken-1
{
    color: #f4511e !important;
}

.deep-orange.darken-2
{
    background-color: #e64a19 !important;
}

.deep-orange-text.text-darken-2
{
    color: #e64a19 !important;
}

.deep-orange.darken-3
{
    background-color: #d84315 !important;
}

.deep-orange-text.text-darken-3
{
    color: #d84315 !important;
}

.deep-orange.darken-4
{
    background-color: #bf360c !important;
}

.deep-orange-text.text-darken-4
{
    color: #bf360c !important;
}

.deep-orange.accent-1
{
    background-color: #ff9e80 !important;
}

.deep-orange-text.text-accent-1
{
    color: #ff9e80 !important;
}

.deep-orange.accent-2
{
    background-color: #ff6e40 !important;
}

.deep-orange-text.text-accent-2
{
    color: #ff6e40 !important;
}

.deep-orange.accent-3
{
    background-color: #ff3d00 !important;
}

.deep-orange-text.text-accent-3
{
    color: #ff3d00 !important;
}

.deep-orange.accent-4
{
    background-color: #dd2c00 !important;
}

.deep-orange-text.text-accent-4
{
    color: #dd2c00 !important;
}

.brown
{
    background-color: #795548 !important;
}

.brown-text
{
    color: #795548 !important;
}

.brown.lighten-5
{
    background-color: #efebe9 !important;
}

.brown-text.text-lighten-5
{
    color: #efebe9 !important;
}

.brown.lighten-4
{
    background-color: #d7ccc8 !important;
}

.brown-text.text-lighten-4
{
    color: #d7ccc8 !important;
}

.brown.lighten-3
{
    background-color: #bcaaa4 !important;
}

.brown-text.text-lighten-3
{
    color: #bcaaa4 !important;
}

.brown.lighten-2
{
    background-color: #a1887f !important;
}

.brown-text.text-lighten-2
{
    color: #a1887f !important;
}

.brown.lighten-1
{
    background-color: #8d6e63 !important;
}

.brown-text.text-lighten-1
{
    color: #8d6e63 !important;
}

.brown.darken-1
{
    background-color: #6d4c41 !important;
}

.brown-text.text-darken-1
{
    color: #6d4c41 !important;
}

.brown.darken-2
{
    background-color: #5d4037 !important;
}

.brown-text.text-darken-2
{
    color: #5d4037 !important;
}

.brown.darken-3
{
    background-color: #4e342e !important;
}

.brown-text.text-darken-3
{
    color: #4e342e !important;
}

.brown.darken-4
{
    background-color: #3e2723 !important;
}

.brown-text.text-darken-4
{
    color: #3e2723 !important;
}

.blue-grey
{
    background-color: #607d8b !important;
}

.blue-grey-text
{
    color: #607d8b !important;
}

.blue-grey.lighten-5
{
    background-color: #eceff1 !important;
}

.blue-grey-text.text-lighten-5
{
    color: #eceff1 !important;
}

.blue-grey.lighten-4
{
    background-color: #cfd8dc !important;
}

.blue-grey-text.text-lighten-4
{
    color: #cfd8dc !important;
}

.blue-grey.lighten-3
{
    background-color: #b0bec5 !important;
}

.blue-grey-text.text-lighten-3
{
    color: #b0bec5 !important;
}

.blue-grey.lighten-2
{
    background-color: #90a4ae !important;
}

.blue-grey-text.text-lighten-2
{
    color: #90a4ae !important;
}

.blue-grey.lighten-1
{
    background-color: #78909c !important;
}

.blue-grey-text.text-lighten-1
{
    color: #78909c !important;
}

.blue-grey.darken-1
{
    background-color: #546e7a !important;
}

.blue-grey-text.text-darken-1
{
    color: #546e7a !important;
}

.blue-grey.darken-2
{
    background-color: #455a64 !important;
}

.blue-grey-text.text-darken-2
{
    color: #455a64 !important;
}

.blue-grey.darken-3
{
    background-color: #37474f !important;
}

.blue-grey-text.text-darken-3
{
    color: #37474f !important;
}

.blue-grey.darken-4
{
    background-color: #263238 !important;
}

.blue-grey-text.text-darken-4
{
    color: #263238 !important;
}

.grey
{
    background-color: #9e9e9e !important;
}

.grey-text
{
    color: #9e9e9e !important;
}

.grey.lighten-5
{
    background-color: #fafafa !important;
}

.grey-text.text-lighten-5
{
    color: #fafafa !important;
}

.grey.lighten-4
{
    background-color: #f5f5f5 !important;
}

.grey-text.text-lighten-4
{
    color: #f5f5f5 !important;
}

.grey.lighten-3
{
    background-color: #eee !important;
}

.grey-text.text-lighten-3
{
    color: #eee !important;
}

.grey.lighten-2
{
    background-color: #e0e0e0 !important;
}

.grey-text.text-lighten-2
{
    color: #e0e0e0 !important;
}

.grey.lighten-1
{
    background-color: #bdbdbd !important;
}

.grey-text.text-lighten-1
{
    color: #bdbdbd !important;
}

.grey.darken-1
{
    background-color: #757575 !important;
}

.grey-text.text-darken-1
{
    color: #757575 !important;
}

.grey.darken-2
{
    background-color: #616161 !important;
}

.grey-text.text-darken-2
{
    color: #616161 !important;
}

.grey.darken-3
{
    background-color: #424242 !important;
}

.grey-text.text-darken-3
{
    color: #424242 !important;
}

.grey.darken-4
{
    background-color: #212121 !important;
}

.grey-text.text-darken-4
{
    color: #212121 !important;
}

.black
{
    background-color: #000 !important;
}

.black-text
{
    color: #000 !important;
}

.white
{
    background-color: #fff !important;
}

.white-text
{
    color: #fff !important;
}

.transparent
{
    background-color: transparent !important;
}

.transparent-text
{
    color: transparent !important;
}

/*
  Gradient color mixin
*/
.gradient-45deg-light-blue-teal
{
    background: #b3e5fc;
    background: -webkit-linear-gradient(45deg, #b3e5fc, #64ffda) !important;
    background:         linear-gradient(45deg, #b3e5fc, #64ffda) !important;
}

.gradient-45deg-light-blue-teal.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(100, 255, 218, .5) !important;
}

.gradient-45deg-indigo-light-blue
{
    background: #3949ab;
    background: -webkit-linear-gradient(45deg, #3949ab, #4fc3f7) !important;
    background:         linear-gradient(45deg, #3949ab, #4fc3f7) !important;
}

.gradient-45deg-indigo-light-blue.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(79, 195, 247, .5) !important;
}

.gradient-45deg-light-blue-indigo
{
    background: #b3e5fc;
    background: -webkit-linear-gradient(45deg, #b3e5fc, #9fa8da) !important;
    background:         linear-gradient(45deg, #b3e5fc, #9fa8da) !important;
}

.gradient-45deg-light-blue
{
    background: #e3f2fd;
    background: -webkit-linear-gradient(45deg, #e3f2fd, #bbdefb) !important;
    background:         linear-gradient(45deg, #e3f2fd, #bbdefb) !important;
}

.gradient-45deg-light-blue-indigo.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(159, 168, 218, .5) !important;
}

.gradient-45deg-yellow-green
{
    background: #ffff8d;
    background: -webkit-linear-gradient(45deg, #ffff8d, #b9f6ca) !important;
    background:         linear-gradient(45deg, #ffff8d, #b9f6ca) !important;
}

.gradient-45deg-yellow-green.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(185, 246, 202, .5) !important;
}

.gradient-45deg-orange-deep-orange
{
    background: #ffe0b2;
    background: -webkit-linear-gradient(45deg, #ffe0b2, #ffccbc) !important;
    background:         linear-gradient(45deg, #ffe0b2, #ffccbc) !important;
}

.gradient-45deg-orange-deep-orange.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(255, 204, 188, .5) !important;
}

.gradient-45deg-deep-purple-purple
{
    background: #d1c4e9;
    background: -webkit-linear-gradient(45deg, #d1c4e9, #f3e5f5) !important;
    background:         linear-gradient(45deg, #d1c4e9, #f3e5f5) !important;
}

.gradient-45deg-deep-purple-purple.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(243, 229, 245, .5) !important;
}

.gradient-45deg-red-pink
{
    background: #ff5252;
    background: -webkit-linear-gradient(45deg, #ff5252, #f48fb1) !important;
    background:         linear-gradient(45deg, #ff5252, #f48fb1) !important;
}

.gradient-45deg-red-pink.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(244, 143, 177, .5) !important;
}

.gradient-45deg-light-green-amber
{
    background: #c5e1a5;
    background: -webkit-linear-gradient(45deg, #c5e1a5, #fff8e1) !important;
    background:         linear-gradient(45deg, #c5e1a5, #fff8e1) !important;
}

.gradient-45deg-light-green-amber.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(255, 248, 225, .5) !important;
}

.gradient-45deg-amber-amber
{
    background: #ff6f00;
    background: -webkit-linear-gradient(45deg, #ff6f00, #ffca28) !important;
    background:         linear-gradient(45deg, #ff6f00, #ffca28) !important;
}

.gradient-45deg-amber-amber.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(255, 202, 40, .5) !important;
}

.gradient-45deg-purple-pink
{
    background: #ea80fc;
    background: -webkit-linear-gradient(45deg, #ea80fc, #fce4ec) !important;
    background:         linear-gradient(45deg, #ea80fc, #fce4ec) !important;
}

.gradient-45deg-purple-pink.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(252, 228, 236, .5) !important;
}

.gradient-45deg-teal-cyan
{
    background: #e0f2f1;
    background: -webkit-linear-gradient(45deg, #e0f2f1, #00e5ff) !important;
    background:         linear-gradient(45deg, #e0f2f1, #00e5ff) !important;
}

.gradient-45deg-teal-cyan.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(0, 229, 255, .5) !important;
}

.gradient-45deg-blue-grey-blue-grey
{
    background: #cfd8dc;
    background: -webkit-linear-gradient(45deg, #cfd8dc, #546e7a) !important;
    background:         linear-gradient(45deg, #cfd8dc, #546e7a) !important;
}

.gradient-45deg-blue-grey-blue-grey.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(84, 110, 122, .5) !important;
}

.gradient-45deg-orange-amber
{
    background: #e65100;
    background: -webkit-linear-gradient(45deg, #e65100, #1976d2) !important;
    background:         linear-gradient(45deg, #e65100, #1976d2) !important;
}

.gradient-45deg-orange-amber.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(25, 118, 210, .5) !important;
}

.gradient-45deg-indigo-blue
{
    background: #303f9f;
    background: -webkit-linear-gradient(45deg, #303f9f, #1976d2) !important;
    background:         linear-gradient(45deg, #303f9f, #1976d2) !important;
}

.gradient-45deg-indigo-blue.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(25, 118, 210, .5) !important;
}

.gradient-45deg-brown-brown
{
    background: #6d4c41;
    background: -webkit-linear-gradient(45deg, #6d4c41, #bbdefb) !important;
    background:         linear-gradient(45deg, #6d4c41, #bbdefb) !important;
}

.gradient-45deg-brown-brown.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(187, 222, 251, .5) !important;
}

.gradient-45deg-blue-grey-blue
{
    background: #263238;
    background: -webkit-linear-gradient(45deg, #263238, #2979ff) !important;
    background:         linear-gradient(45deg, #263238, #2979ff) !important;
}

.gradient-45deg-blue-grey-blue.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(41, 121, 255, .5) !important;
}

.gradient-45deg-purple-deep-orange
{
    background: #8e24aa;
    background: -webkit-linear-gradient(45deg, #8e24aa, #ff6e40) !important;
    background:         linear-gradient(45deg, #8e24aa, #ff6e40) !important;
}

.gradient-45deg-purple-deep-orange.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(255, 110, 64, .5) !important;
}

.gradient-45deg-green-teal
{
    background: #43a047;
    background: -webkit-linear-gradient(45deg, #43a047, #1de9b6) !important;
    background:         linear-gradient(45deg, #43a047, #1de9b6) !important;
}

.gradient-45deg-green-teal.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(29, 233, 182, .5) !important;
}

.gradient-45deg-purple-light-blue
{
    background: #e040fb;
    background: -webkit-linear-gradient(45deg, #e040fb, #4fc3f7) !important;
    background:         linear-gradient(45deg, #e040fb, #4fc3f7) !important;
}

.gradient-45deg-purple-light-blue.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(79, 195, 247, .5) !important;
}

.gradient-45deg-cyan-cyan
{
    background: #18ffff;
    background: -webkit-linear-gradient(45deg, #18ffff, #00e5ff) !important;
    background:         linear-gradient(45deg, #18ffff, #00e5ff) !important;
}

.gradient-45deg-cyan-cyan.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(0, 229, 255, .5) !important;
}

.gradient-45deg-yellow-teal
{
    background: #fff9c4;
    background: -webkit-linear-gradient(45deg, #fff9c4, #64ffda) !important;
    background:         linear-gradient(45deg, #fff9c4, #64ffda) !important;
}

.gradient-45deg-yellow-teal.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(100, 255, 218, .5) !important;
}

.gradient-45deg-purple-deep-purple
{
    background: #7b1fa2;
    background: -webkit-linear-gradient(45deg, #7b1fa2, #7c4dff) !important;
    background:         linear-gradient(45deg, #7b1fa2, #7c4dff) !important;
}

.gradient-45deg-purple-deep-purple.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(124, 77, 255, .5) !important;
}

.gradient-45deg-cyan-light-green
{
    background: #0097a7;
    background: -webkit-linear-gradient(45deg, #0097a7, #b2ff59) !important;
    background:         linear-gradient(45deg, #0097a7, #b2ff59) !important;
}

.gradient-45deg-cyan-light-green.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(178, 255, 89, .5) !important;
}

.gradient-45deg-purple-amber
{
    background: #d500f9;
    background: -webkit-linear-gradient(45deg, #d500f9, #ffa000) !important;
    background:         linear-gradient(45deg, #d500f9, #ffa000) !important;
}

.gradient-45deg-purple-amber.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(255, 160, 0, .5) !important;
}

.gradient-45deg-indigo-purple
{
    background: #303f9f;
    background: -webkit-linear-gradient(45deg, #303f9f, #7b1fa2) !important;
    background:         linear-gradient(45deg, #303f9f, #7b1fa2) !important;
}

.gradient-45deg-indigo-purple.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(123, 31, 162, .5) !important;
}

.gradient-45deg-deep-purple-blue
{
    background: #6200ea;
    background: -webkit-linear-gradient(45deg, #6200ea, #1976d2) !important;
    background:         linear-gradient(45deg, #6200ea, #1976d2) !important;
}

.gradient-45deg-deep-purple-blue.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(25, 118, 210, .5) !important;
}

.gradient-45deg-deep-orange-orange
{
    background: #bf360c;
    background: -webkit-linear-gradient(45deg, #bf360c, #f57c00) !important;
    background:         linear-gradient(45deg, #bf360c, #f57c00) !important;
}

.gradient-45deg-deep-orange-orange.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(245, 124, 0, .5) !important;
}

.gradient-45deg-light-blue-cyan
{
    background: #0288d1;
    background: -webkit-linear-gradient(45deg, #0288d1, #26c6da) !important;
    background:         linear-gradient(45deg, #0288d1, #26c6da) !important;
}

.gradient-45deg-light-blue-cyan.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(38, 198, 218, .5) !important;
}

.gradient-45deg-blue-indigo
{
    background: #2962ff;
    background: -webkit-linear-gradient(45deg, #2962ff, #3949ab) !important;
    background:         linear-gradient(45deg, #2962ff, #3949ab) !important;
}

.gradient-45deg-blue-indigo.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(57, 73, 171, .5) !important;
}

.gradient-45deg-semi-dark
{
    background: #00aeff;
    background: -webkit-linear-gradient(right, #00aeff, #3369e6) !important;
    background:         linear-gradient(-90deg, #00aeff, #3369e6) !important;
}

.gradient-45deg-semi-dark.gradient-shadow
{
    box-shadow: 0 6px 20px 0 rgba(51, 105, 230, .5) !important;
}

.btn-light-materialize-red
{
    color: #e51c23 !important;
    background-color: #fdeaeb;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-materialize-red:hover,
.btn-light-materialize-red:focus,
.btn-light-materialize-red.hover
{
    color: #fff !important;
    background-color: #ea454b !important;
}

.btn-light-materialize-red:active,
.btn-light-materialize-red.active
{
    color: #fff !important;
    background-color: #d0181e !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-materialize-red:before
{
    background: #e51c23 !important;
}

.btn-light-red
{
    color: #f44336 !important;
    background-color: #ffebee;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-red:hover,
.btn-light-red:focus,
.btn-light-red.hover
{
    color: #fff !important;
    background-color: #ef5350 !important;
}

.btn-light-red:active,
.btn-light-red.active
{
    color: #fff !important;
    background-color: #e53935 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-red:before
{
    background: #f44336 !important;
}

.btn-light-pink
{
    color: #e91e63 !important;
    background-color: #fce4ec;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-pink:hover,
.btn-light-pink:focus,
.btn-light-pink.hover
{
    color: #fff !important;
    background-color: #ec407a !important;
}

.btn-light-pink:active,
.btn-light-pink.active
{
    color: #fff !important;
    background-color: #d81b60 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-pink:before
{
    background: #e91e63 !important;
}

.btn-light-purple
{
    color: #9c27b0 !important;
    background-color: #f3e5f5;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-purple:hover,
.btn-light-purple:focus,
.btn-light-purple.hover
{
    color: #fff !important;
    background-color: #ab47bc !important;
}

.btn-light-purple:active,
.btn-light-purple.active
{
    color: #fff !important;
    background-color: #8e24aa !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-purple:before
{
    background: #9c27b0 !important;
}

.btn-light-deep-purple
{
    color: #673ab7 !important;
    background-color: #ede7f6;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-deep-purple:hover,
.btn-light-deep-purple:focus,
.btn-light-deep-purple.hover
{
    color: #fff !important;
    background-color: #7e57c2 !important;
}

.btn-light-deep-purple:active,
.btn-light-deep-purple.active
{
    color: #fff !important;
    background-color: #5e35b1 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-deep-purple:before
{
    background: #673ab7 !important;
}

.btn-light-indigo
{
    color: #3f51b5 !important;
    background-color: #e8eaf6;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-indigo:hover,
.btn-light-indigo:focus,
.btn-light-indigo.hover
{
    color: #fff !important;
    background-color: #5c6bc0 !important;
}

.btn-light-indigo:active,
.btn-light-indigo.active
{
    color: #fff !important;
    background-color: #3949ab !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-indigo:before
{
    background: #3f51b5 !important;
}

.btn-light-blue
{
    color: #2196f3 !important;
    background-color: #e3f2fd;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-blue:hover,
.btn-light-blue:focus,
.btn-light-blue.hover
{
    color: #fff !important;
    background-color: #42a5f5 !important;
}

.btn-light-blue:active,
.btn-light-blue.active
{
    color: #fff !important;
    background-color: #1e88e5 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-blue:before
{
    background: #2196f3 !important;
}

.btn-light-light-blue
{
    color: #03a9f4 !important;
    background-color: #e1f5fe;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-light-blue:hover,
.btn-light-light-blue:focus,
.btn-light-light-blue.hover
{
    color: #fff !important;
    background-color: #29b6f6 !important;
}

.btn-light-light-blue:active,
.btn-light-light-blue.active
{
    color: #fff !important;
    background-color: #039be5 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-light-blue:before
{
    background: #03a9f4 !important;
}

.btn-light-cyan
{
    color: #00bcd4 !important;
    background-color: #e0f7fa;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-cyan:hover,
.btn-light-cyan:focus,
.btn-light-cyan.hover
{
    color: #fff !important;
    background-color: #26c6da !important;
}

.btn-light-cyan:active,
.btn-light-cyan.active
{
    color: #fff !important;
    background-color: #00acc1 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-cyan:before
{
    background: #00bcd4 !important;
}

.btn-light-teal
{
    color: #009688 !important;
    background-color: #e0f2f1;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-teal:hover,
.btn-light-teal:focus,
.btn-light-teal.hover
{
    color: #fff !important;
    background-color: #26a69a !important;
}

.btn-light-teal:active,
.btn-light-teal.active
{
    color: #fff !important;
    background-color: #00897b !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-teal:before
{
    background: #009688 !important;
}

.btn-light-green
{
    color: #4caf50 !important;
    background-color: #e8f5e9;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-green:hover,
.btn-light-green:focus,
.btn-light-green.hover
{
    color: #fff !important;
    background-color: #66bb6a !important;
}

.btn-light-green:active,
.btn-light-green.active
{
    color: #fff !important;
    background-color: #43a047 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-green:before
{
    background: #4caf50 !important;
}

.btn-light-light-green
{
    color: #8bc34a !important;
    background-color: #f1f8e9;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-light-green:hover,
.btn-light-light-green:focus,
.btn-light-light-green.hover
{
    color: #fff !important;
    background-color: #9ccc65 !important;
}

.btn-light-light-green:active,
.btn-light-light-green.active
{
    color: #fff !important;
    background-color: #7cb342 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-light-green:before
{
    background: #8bc34a !important;
}

.btn-light-lime
{
    color: #cddc39 !important;
    background-color: #f9fbe7;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-lime:hover,
.btn-light-lime:focus,
.btn-light-lime.hover
{
    color: #fff !important;
    background-color: #d4e157 !important;
}

.btn-light-lime:active,
.btn-light-lime.active
{
    color: #fff !important;
    background-color: #c0ca33 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-lime:before
{
    background: #cddc39 !important;
}

.btn-light-yellow
{
    color: #ffeb3b !important;
    background-color: #fffde7;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-yellow:hover,
.btn-light-yellow:focus,
.btn-light-yellow.hover
{
    color: #fff !important;
    background-color: #ffee58 !important;
}

.btn-light-yellow:active,
.btn-light-yellow.active
{
    color: #fff !important;
    background-color: #fdd835 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-yellow:before
{
    background: #ffeb3b !important;
}

.btn-light-amber
{
    color: #ffc107 !important;
    background-color: #fff8e1;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-amber:hover,
.btn-light-amber:focus,
.btn-light-amber.hover
{
    color: #fff !important;
    background-color: #ffca28 !important;
}

.btn-light-amber:active,
.btn-light-amber.active
{
    color: #fff !important;
    background-color: #ffb300 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-amber:before
{
    background: #ffc107 !important;
}

.btn-light-orange
{
    color: #ff9800 !important;
    background-color: #fff3e0;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-orange:hover,
.btn-light-orange:focus,
.btn-light-orange.hover
{
    color: #fff !important;
    background-color: #ffa726 !important;
}

.btn-light-orange:active,
.btn-light-orange.active
{
    color: #fff !important;
    background-color: #fb8c00 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-orange:before
{
    background: #ff9800 !important;
}

.btn-light-deep-orange
{
    color: #ff5722 !important;
    background-color: #fbe9e7;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-deep-orange:hover,
.btn-light-deep-orange:focus,
.btn-light-deep-orange.hover
{
    color: #fff !important;
    background-color: #ff7043 !important;
}

.btn-light-deep-orange:active,
.btn-light-deep-orange.active
{
    color: #fff !important;
    background-color: #f4511e !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-deep-orange:before
{
    background: #ff5722 !important;
}

.btn-light-brown
{
    color: #795548 !important;
    background-color: #efebe9;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-brown:hover,
.btn-light-brown:focus,
.btn-light-brown.hover
{
    color: #fff !important;
    background-color: #8d6e63 !important;
}

.btn-light-brown:active,
.btn-light-brown.active
{
    color: #fff !important;
    background-color: #6d4c41 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-brown:before
{
    background: #795548 !important;
}

.btn-light-blue-grey
{
    color: #607d8b !important;
    background-color: #eceff1;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-blue-grey:hover,
.btn-light-blue-grey:focus,
.btn-light-blue-grey.hover
{
    color: #fff !important;
    background-color: #78909c !important;
}

.btn-light-blue-grey:active,
.btn-light-blue-grey.active
{
    color: #fff !important;
    background-color: #546e7a !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-blue-grey:before
{
    background: #607d8b !important;
}

.btn-light-grey
{
    color: #9e9e9e !important;
    background-color: #fafafa;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .1), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .08);
}

.btn-light-grey:hover,
.btn-light-grey:focus,
.btn-light-grey.hover
{
    color: #fff !important;
    background-color: #bdbdbd !important;
}

.btn-light-grey:active,
.btn-light-grey.active
{
    color: #fff !important;
    background-color: #757575 !important;
}

/* timeline */
.widget-timeline li.timeline-items.timeline-icon-grey:before
{
    background: #9e9e9e !important;
}

.font-size-1-2
{
    font-size: 1.2rem;
}

.font-size-1-5
{
    font-size: 1.5rem;
}

.font-size-1-8
{
    font-size: 1.8rem;
}
.font-size-2
{
    font-size: 2rem;
}

.font-size-1
{
    font-size: 1rem;
}

.font-size-0-9
{
    font-size: .9rem;
}
.font-size-0-8
{
    font-size: .8rem;
}

.justify-align {
    text-align: justify;
}

.dolibarr-blue {
    background: #263C5C;
}

.dolibarr-blue-text {
    color: #263C5C;
}

