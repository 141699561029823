@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


body {
    font-family: 'Ubuntu', sans-serif;
}

h1 {
    font-size: 2.5rem !important;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    line-height: 1.5em;
    padding-bottom: 15px;
    position: relative;
    text-transform: uppercase;
    font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
}

h1:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 8px;
    width: 55px;
    border-radius: 0.3em;
    background-color: #FF0000;
}


h1:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 3px;
    height: 2px;
    width: 95%;
    max-width: 255px;
    background-color: #1565c0;
}

.form-subtitle {
    position: relative;
    margin-bottom:26px !important;
    font-weight: 600;
    font-size: 1.2rem;
    padding-left:12px;
    color: #333333;
}

.form-subtitle:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 5px;
    border-radius: 0.3em;
    background-color: #AAAAAA;
}


img {
    max-width: 100%;
}

.strikethrough-text
{
    text-decoration: line-through;
}

table tr td {
    border-radius: 0 !important;
    padding-left: 8px !important;
}

.sticker
{
    padding: 2px 8px;
    border-radius: 0.5em;
}

li.error
{
    color:red;
}

.footer-title {
    font-size: 1.6rem !important;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    line-height: 1.5em;
    padding-bottom: 15px;
    color:  #FFFFFF;
    position: relative;
    text-transform: uppercase;
    text-shadow: 0px 0px 20px rgba(0,0,0,.8);
    font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
}



.footer-title:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 8px;
    width: 55px;
    border-radius: 0.3em;
    background-color: #FF0000;
}


.footer-title:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 3px;
    height: 2px;
    width: 95%;
    max-width: 255px;
    background-color: #FFFF00;
}

.card-title-2 {
    font-size: 1.6rem !important;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    line-height: 1.5em;
    padding-bottom: 15px;
    position: relative;
    text-transform: uppercase;
    text-shadow: 0px 0px 20px rgba(0,0,0,.2);
    font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
}



.card-title-2:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 8px;
    width: 55px;
    border-radius: 0.3em;
    background-color: #FF0000;
}


.card-title-2:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 3px;
    height: 2px;
    width: 100%;

    background-color: #1565c0;
}